import { ReactNode } from 'react'

import { ProjectsContext } from 'contexts'
import { useProjectsController } from 'controllers'

type Props = {
  children: ReactNode
}

export const ProjectsProvider = ({ children }: Props) => {
  return <ProjectsContext.Provider value={useProjectsController()}>{children}</ProjectsContext.Provider>
}
