import { CSSObject } from 'styled-components'

export const lotCardStyles = {
  width: 'calc(25% - 16px)',
  margin: 1,
  padding: 2,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative'
}

export const noProjectLotEmptyStateStyles: CSSObject = {
  zIndex: 1,
  img: {
    width: '60%'
  },
  h6: {
    fontSize: '0.75rem',
    textAlign: 'center'
  }
}
export const projectPhotosBoxStyles: CSSObject = {
  height: 140,
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: 1,
  overflow: 'hidden',
  mb: 2
}
