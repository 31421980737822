import styled, { css, CSSObject } from 'styled-components'
import { variables } from 'styles'

type Props = {
  $styles?: CSSObject
  $flex?: boolean
  $flexColumn?: boolean
  $hCenter?: boolean
  $vCenter?: boolean
  $center?: boolean
  $gap?: string
  $fullHeight?: boolean
  $fullWidth?: boolean
}

export const Container = styled.main<Props>`
  margin: 0 auto;
  padding: 16px;
  position: relative;

  > header {
    display: flex;
    justify-content: center;
    margin: 32px 0;

    h2 {
      padding-bottom: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.custom.base.text};
    }
  }

  ${(props: Props) =>
    props.$flex &&
    css`
      display: flex;
    `}
  ${(props: Props) =>
    props.$flexColumn &&
    css`
      display: flex;
      flex-direction: column;
    `}
  ${(props: Props) =>
    props.$hCenter &&
    css`
      justify-content: center;
    `}
  ${(props: Props) =>
    props.$vCenter &&
    css`
      align-items: center;
    `}
  ${(props: Props) =>
    props.$center &&
    css`
      justify-content: center;
      align-items: center;
    `}
  ${(props: Props) =>
    props.$gap &&
    css`
      gap: ${props.$gap};
    `}
  ${(props: Props) =>
    props.$fullHeight &&
    css`
      height: calc(100vh - ${variables.topbarHeight});
    `}
  ${(props: Props) =>
    props.$fullWidth
      ? css`
          width: 100%;
        `
      : css`
          max-width: ${variables.containerWidth}px;
        `}
  ${(props: Props) => props.$styles}
`
