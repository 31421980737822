import { InventoryClayLot, InventoryCustomClayWeight, ProjectInventoriableClay } from 'models'

export class InventoryCustomClay {
  id: Id
  name: string
  hexColor: string
  weight: number
  usedWeight: number

  inventoryClayLots: InventoryClayLot[] = []
  inventoryCustomClayWeights: InventoryCustomClayWeight[] = []
  projectInventoriableClays: ProjectInventoriableClay[] = []

  constructor(inventoryCustomClayParams) {
    const {
      inventoryClayLots: inventoryClayLotsParams,
      inventoryCustomClayWeights: inventoryCustomClayWeightsParams
    } = inventoryCustomClayParams

    const inventoryCustomClayLots = inventoryClayLotsParams?.map(params => new InventoryClayLot(params))
    const inventoryCustomClayWeights = inventoryCustomClayWeightsParams?.map(
      params => new InventoryCustomClayWeight(params)
    )

    Object.assign(this, {
      ...inventoryCustomClayParams,
      inventoryCustomClayLots,
      inventoryCustomClayWeights
    })
  }

  get availableWeight() {
    return this.weight - this.usedWeight
  }
}
