import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { Card, CardMedia, CardContent, Typography } from '@mui/material'

import { TypographyEllipsis } from 'components'
import { InventoryCustomClay } from 'models'
import { sliceAt } from 'utils'

import {
  cardContentStyles,
  cardStyles,
  cardPortraitStyles,
  PhotosContainer,
  ColorSwatchContainer,
  ColorSwatch
} from './styles'

type Props = {
  inventoryCustomClay: InventoryCustomClay
  portrait?: boolean
  width?: string
  height?: string
  showAvailableWeight?: boolean
}

export const InventoryCustomClayCard = ({
  inventoryCustomClay,
  portrait,
  width,
  height,
  showAvailableWeight
}: Props) => {
  const clays = inventoryCustomClay.inventoryClayLots.map(({ inventoryClay }) => inventoryClay.clay)
  const containerStyles = {
    ...cardStyles,
    ...(portrait && cardPortraitStyles),
    ...(width && { width }),
    ...(height && { height })
  }

  return (
    <Card
      sx={containerStyles}
      key={inventoryCustomClay.id}
      component={Link}
      to={`/inventory/custom-clays/${inventoryCustomClay.id}`}
    >
      <PhotosContainer>
        {sliceAt(clays, 0, 3).map((clay, index) => (
          <CardMedia
            key={index}
            component="img"
            image={clay.photoUrl}
            alt={clay.color}
            sx={{ width: '50%', height: 60 }}
          />
        ))}
        <ColorSwatchContainer>
          <ColorSwatch hex={inventoryCustomClay.hexColor} />
        </ColorSwatchContainer>
      </PhotosContainer>
      <CardContent sx={cardContentStyles}>
        <TypographyEllipsis variant="h6" color="text.secondary" lineClamp={2}>
          {inventoryCustomClay.name}
        </TypographyEllipsis>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <strong>{t('inventory-custom-clay:weight')}:</strong> {inventoryCustomClay.weight}g
        </Typography>
        {showAvailableWeight && (
          <Typography variant="body2" color="text.secondary">
            <strong>{t('inventory-custom-clay:available-weight')}:</strong>{' '}
            {inventoryCustomClay.availableWeight}g
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
