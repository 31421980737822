import { t } from 'i18next'
import { Box, Typography } from '@mui/material'

import { PageContainer } from 'components'

import { Tutorial, TUTORIAL_SETTINGS } from './constants'
import { ChannelLink, List, ListItem, VideoThumbIFrame, videoThumbContainerStyles } from './styles'

export const TutorialsPage = () => {
  return (
    <PageContainer flexColumn>
      <Typography variant="h4" gutterBottom>
        {t('tutorials-title')}
      </Typography>
      <List>
        {TUTORIAL_SETTINGS.map(tutorial => (
          <ListItem key={tutorial.id}>
            <Typography variant="h5" gutterBottom>
              {tutorial.channelName}
            </Typography>
            <TutorialDescription tutorial={tutorial} />

            <Box sx={videoThumbContainerStyles}>
              {tutorial.videoUrls.map(videoUrl => (
                <VideoThumbIFrame
                  key={videoUrl}
                  src={videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              ))}
            </Box>
          </ListItem>
        ))}
      </List>
    </PageContainer>
  )
}

type TutorialDescriptionProps = {
  tutorial: Tutorial
}

const TutorialDescription = ({ tutorial }: TutorialDescriptionProps) => {
  const [descriptionStart, descriptionEnd] = tutorial.description.split(tutorial.channelName)
  return (
    <>
      {descriptionStart}
      <ChannelLink href={tutorial.channelUrl}>{tutorial.channelName}</ChannelLink>
      {descriptionEnd}
    </>
  )
}
