import Location from 'app-location'
import * as Yup from 'yup'

export const InventoryCustomClaysEndpoint: Location = new Location('/inventory_custom_clays')
export const InventoryCustomClayEndpoint: Location = new Location(
  '/inventory_custom_clays/:inventoryCustomClayId',
  {
    inventoryCustomClayId: Yup.string()
  }
)
