export const TUTORIAL_SETTINGS = [
  {
    id: 1,
    channelName: 'Sah Biscuit',
    channelUrl: 'https://www.youtube.com/@sahbiscuit',
    description:
      'For those who are just getting started with air dry clay, Sah Biscuit channel is a great place to get started. There are playlists for all levels, from beginner to advanced.',
    videoUrls: [
      'https://www.youtube-nocookie.com/embed/videoseries?list=PLYXpPa9hIKILP7kkIjNns3bK3-RGfenOJ',
      'https://www.youtube-nocookie.com/embed/videoseries?list=PLYXpPa9hIKIKFsksmCFSD9PpckkiIZeNG'
    ]
  },
  {
    id: 2,
    channelName: 'Almir Nascimento',
    channelUrl: 'https://www.youtube.com/@AlmirNascimento',
    description: 'Almir Nascimento channel presents a large set of animated character tutorials.',
    videoUrls: [
      'https://www.youtube-nocookie.com/embed/T81Y0-T3nxk',
      'https://www.youtube-nocookie.com/embed/u3m0IZUshlU'
    ]
  },
  {
    id: 3,
    channelName: 'Luiz Costa Biscuit',
    channelUrl: 'https://www.youtube.com/@luizcostabiscuit8841',
    description: 'Luiz Costa Biscuit channel holds a large realistic sculpture tutorial collection.',
    videoUrls: [
      'https://www.youtube-nocookie.com/embed/ZQZ4vGuUHqU',
      'https://www.youtube-nocookie.com/embed/f7bLWG6mwLc'
    ]
  }
] as const

export type Tutorial = (typeof TUTORIAL_SETTINGS)[number]
