export const variables = {
  topbarHeight: 72,
  containerWidth: 960,
  createResourceButtonHeight: 68
} as const

export const BREAKPOINTS = {
  xs: '575.98' /* portrait phones */,
  sm: '767.98' /* landscape phones */,
  md: '991.98' /* tablets */,
  lg: '1199.98' /* desktops */,
  xl: '1399.98' /* large desktops */
} as const
