import { DeleteOutlined, ModeEditOutlineOutlined } from '@mui/icons-material'
import { t } from 'i18next'

import { MenuItemProps } from 'components'

export const projectMenuItems: () => MenuItemProps[] = () => [
  {
    id: 'edit',
    icon: ModeEditOutlineOutlined,
    label: t('actions.update'),
    onClick: () => {}
  },
  {
    id: 'delete',
    icon: DeleteOutlined,
    label: t('actions.delete'),
    onClick: () => {}
  }
]
