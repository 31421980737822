import { PageContainer } from 'components'
import { InventoryCustomClaysProvider } from 'providers'

import { CreateInventoryCustomClayForm } from 'pages/inventory/InventoryCustomClaysPage/components'

const ColorMixerPageFC = () => {
  return (
    <PageContainer flexColumn>
      <CreateInventoryCustomClayForm />
    </PageContainer>
  )
}

export const ColorMixerPage = () => {
  return (
    <InventoryCustomClaysProvider>
      <ColorMixerPageFC />
    </InventoryCustomClaysProvider>
  )
}
