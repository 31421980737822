import styledComponent, { CSSObject } from 'styled-components'
import { DefaultTheme, styled } from '@mui/material/styles'
import { TextField, Tooltip, tooltipClasses } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'

type StyledTextFieldProps = {
  error?: boolean
} & DefaultTheme

type ContainerProps = {
  styles: CSSObject
} & DefaultTheme

export const Container = styledComponent.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ styles }) => styles}
`

export const StyledTextField = styled(TextField)<StyledTextFieldProps>(({ theme, error }) => ({
  '&.MuiFormControl-root': {
    width: '100%'
  },
  '&.MuiTextField-root': {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 2,
        ...(error && {
          borderColor: theme.palette.error.main
        })
      }
    }
  }
}))

export const Error = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.custom.invertedBase.text,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '0.5rem'
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.custom.invertedBase.text,
    boxShadow: theme.shadows[1]
  }
}))

export const AlertIcon = styled(ErrorOutline)({
  position: 'absolute',
  right: 14
})
