import { useCallback, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { t } from 'i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EmailOutlined } from '@mui/icons-material'

import { Button, Input } from 'components'
import { useToastContext } from 'contexts'
import { useAuth } from 'hooks'
import { ForgotPasswordEndpoint } from 'routes'

import { Container, Title, FormContainer, Form } from './styles'

const schema = Yup.object().shape({
  email: Yup.string().required().email().label('Email')
})

export const ForgotPassword = () => {
  const [forgotPassword, error, loading, status] = useAuth(ForgotPasswordEndpoint)
  const { showToast } = useToastContext()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: { email: '' }
  })

  const onSubmit = useCallback(
    data => {
      forgotPassword({ user: data })
    },
    [forgotPassword]
  )

  useEffect(() => {
    if (status !== 201) return

    showToast({
      type: 'success',
      title: t('forgot-password:success.default.title'),
      description: t('forgot-password:success.default.description')
    })
  }, [status, showToast])

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: t('forgot-password:errors.default'),
      description: error.message
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>{t('forgot-password:title')}</Title>
          <Input
            type="email"
            name="email"
            label="Email"
            placeholder="Email"
            icon={EmailOutlined}
            control={control}
            autoComplete="username"
            errorMessage={errors.email?.message as string}
          />

          <Button
            type="submit"
            label={t('actions.submit')}
            styles={{ marginTop: '1rem' }}
            loading={loading}
          />
        </Form>
      </FormContainer>
    </Container>
  )
}
