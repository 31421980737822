import { useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EmailOutlined, LockOutlined, PersonOutlined, CameraAltOutlined } from '@mui/icons-material'
import { Typography, Divider } from '@mui/material'

import { Button, Input, DotLoader } from 'components'
import { useUserAccountController } from 'controllers'

import { LanguageForm } from './components'
import {
  userSchema,
  userAvatarSchema,
  userUpdatePasswordSchema,
  UserFormValues,
  UserUpdatePasswordSchemaFormValues
} from './schemas'
import { resetAvatarAnimation } from './utils'

import { Container, Title, FormsContainer, Form, InputGroup, AvatarInput } from './styles'
import FlowerAvatarPlaceholder from 'assets/images/flower.png'

export const UserAccountPage = () => {
  const avatarImgRef = useRef<HTMLImageElement>()
  const { t } = useTranslation()

  const {
    fetchCurrentUser,
    fetchCurrentUserLoading,
    currentUser,
    updateDeviseCurrentUser,
    updateDeviseCurrentUserLoading,
    updatePassword,
    updatePasswordLoading,
    updateCurrentUser,
    updateCurrentUserLoading,
    updateCurrentUserError
  } = useUserAccountController()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<UserFormValues>({
    resolver: yupResolver(userSchema)
  })

  const {
    control: controlPassForm,
    handleSubmit: handleSubmitPassForm,
    formState: { errors: errorsPassForm }
  } = useForm<UserUpdatePasswordSchemaFormValues>({
    resolver: yupResolver(userUpdatePasswordSchema),
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: ''
    }
  })

  const { avatarUrl } = currentUser || {}

  useEffect(() => {
    fetchCurrentUser()
  }, []) /* eslint-disable-line */

  useEffect(() => {
    if (!currentUser) return

    const { firstName, lastName, email } = currentUser
    const userData = { first_name: firstName, last_name: lastName, email }

    reset(userData)
  }, [currentUser, reset])

  useEffect(() => {
    if (updateCurrentUserError) {
      resetAvatarAnimation(avatarImgRef.current)
      if (avatarImgRef.current) avatarImgRef.current.src = avatarUrl
    }
  }, [updateCurrentUserError, avatarUrl])

  const onSubmit = useCallback(
    data => {
      updateDeviseCurrentUser({ user: data })
    },
    [updateDeviseCurrentUser]
  )

  const onSubmitPassword = useCallback(
    data => {
      updatePassword({ user: data })
    },
    [updatePassword]
  )

  const handleAvatarChange = useCallback(
    async e => {
      const file = e.target.files[0]
      await userAvatarSchema.validate({ file })

      resetAvatarAnimation(avatarImgRef.current)
      avatarImgRef.current.src = URL.createObjectURL(file)

      const formData = new FormData()
      formData.append('user[avatar]', file)

      updateCurrentUser(formData)
    },
    [updateCurrentUser]
  )

  if (fetchCurrentUserLoading || !currentUser) return <>{t('loading')}</>

  return (
    <Container>
      <Title>{t('account:resource')}</Title>

      <AvatarInput>
        <figure>
          <img ref={avatarImgRef} src={avatarUrl || FlowerAvatarPlaceholder} alt={currentUser.firstName} />
          {!avatarUrl && (
            <Typography
              variant="caption"
              color="text.secondary"
              component="figcaption"
              sx={{ textAlign: 'center' }}
            >
              <a href="https://www.flaticon.com/free-icons/flower" target="_blank" rel="noreferrer">
                {/* eslint-disable-line i18next/no-literal-string */}
                Freepik - Flaticon
              </a>
            </Typography>
          )}
        </figure>

        <label htmlFor="avatar">
          {updateCurrentUserLoading ? <DotLoader small /> : <CameraAltOutlined />}
          <input type="file" id="avatar" accept="image/png, image/jpeg" onChange={handleAvatarChange} />
        </label>
      </AvatarInput>

      <FormsContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup>
            <Input
              control={control}
              type="text"
              name="first_name"
              label={t('user:first-name')}
              placeholder={t('user:first-name')}
              icon={PersonOutlined}
              errorMessage={errors.first_name?.message as string}
            />
            <Input
              control={control}
              type="text"
              name="last_name"
              label={t('user:last-name')}
              placeholder={t('user:last-name')}
              icon={PersonOutlined}
              errorMessage={errors.last_name?.message as string}
            />
          </InputGroup>
          <Input
            control={control}
            type="email"
            name="email"
            label={t('user:email')}
            placeholder={t('user:email')}
            icon={EmailOutlined}
            errorMessage={errors.email?.message as string}
            styles={{ marginTop: '0.8rem' }}
          />
          <Input
            control={control}
            type="password"
            name="current_password"
            label={t('account:current-password')}
            placeholder={t('account:current-password')}
            icon={LockOutlined}
            errorMessage={errors.password?.message as string}
            autoComplete="on"
            styles={{ marginTop: '0.8rem' }}
          />

          <Button
            type="submit"
            label={t('account:actions.update')}
            loading={updateDeviseCurrentUserLoading}
            styles={{ marginTop: '0.8rem' }}
          />
        </Form>

        <Form onSubmit={handleSubmitPassForm(onSubmitPassword)}>
          <Input
            control={controlPassForm}
            type="password"
            name="current_password"
            label={t('account:current-password')}
            placeholder={t('account:current-password')}
            icon={LockOutlined}
            errorMessage={errorsPassForm.current_password?.message as string}
            autoComplete="on"
          />
          <Input
            control={controlPassForm}
            type="password"
            name="password"
            label={t('account:new-password')}
            placeholder={t('account:new-password')}
            icon={LockOutlined}
            errorMessage={errorsPassForm.password?.message as string}
            autoComplete="on"
            styles={{ marginTop: '0.8rem' }}
          />
          <Input
            control={controlPassForm}
            type="password"
            name="password_confirmation"
            label={t('account:confirm-password')}
            placeholder={t('account:confirm-password')}
            icon={LockOutlined}
            errorMessage={errorsPassForm.password_confirmation?.message as string}
            autoComplete="on"
            styles={{ marginTop: '0.8rem' }}
          />

          <Button
            type="submit"
            label={t('account:actions.update-password')}
            loading={updatePasswordLoading}
            styles={{ marginTop: '0.8rem' }}
          />
        </Form>
      </FormsContainer>

      <Divider flexItem />

      <LanguageForm />
    </Container>
  )
}
