import { Navigate, Outlet } from 'react-router-dom'
import { useAuthToken } from 'hooks'
import { useCurrentUserContext } from 'contexts'

type Props = {
  auth?: boolean
  admin?: boolean
  isPublic?: boolean
}

export const RouteManager = ({ auth, admin, isPublic }: Props) => {
  const { token } = useAuthToken()
  const { currentUser } = useCurrentUserContext()

  const hasAccess = !!token === !auth
  const hasAdminAccess = admin && currentUser.admin

  if (isPublic) return <Outlet />
  if (hasAccess && hasAdminAccess) return <Outlet />
  if (hasAccess && !admin) return <Outlet />
  if (!!token && auth) return <Navigate to={{ pathname: '/' }} />

  return <Navigate to={{ pathname: '/sign-in' }} />
}
