import { useEffect } from 'react'
import { t } from 'i18next'

import { UserEndpoint, UsersEndpoint } from 'routes/locations'

import { useToastContext, useCurrentUserContext } from 'contexts'
import { useApi, useAuthToken } from 'hooks'
import { User } from 'models'

export const useUserAccountController = () => {
  const { showToast } = useToastContext()
  const { currentUser: user } = useAuthToken()
  const { setCurrentUser } = useCurrentUserContext()

  const [fetchCurrentUser, currentUser, fetchCurrentUserLoading] = useApi<User>(UserEndpoint, 'GET', {
    userId: user.id
  })
  const [
    updateDeviseCurrentUser,
    ,
    updateDeviseCurrentUserError,
    updateDeviseCurrentUserLoading,
    updateDeviseCurrentUserStatus
  ] = useApi<User>(UsersEndpoint, 'PUT')
  const [updatePassword, , updatePasswordError, updatePasswordLoading, updatePasswordStatus] = useApi<User>(
    UsersEndpoint,
    'PUT'
  )
  const [
    updateCurrentUser,
    userWithAvatar,
    updateCurrentUserError,
    updateCurrentUserLoading,
    updateCurrentUserStatus
  ] = useApi<User>(UserEndpoint, 'PUT', { userId: user.id })

  const isSuccess =
    updateDeviseCurrentUserStatus < 400 || updatePasswordStatus < 400 || updateCurrentUserStatus < 400
  const isError = updateDeviseCurrentUserError || updatePasswordError
  const errorMessage = updateDeviseCurrentUserError?.message || updatePasswordError?.message

  useEffect(() => {
    if (isSuccess) {
      showToast({
        type: 'success',
        title: t('account:success.default.title'),
        description: t('account:success.default.description')
      })
    }
  }, [isSuccess, showToast])

  useEffect(() => {
    if (isError) {
      showToast({
        type: 'error',
        title: t('account:errors.default'),
        description: errorMessage
      })
    }
  }, [isError, errorMessage, showToast])

  useEffect(() => {
    if (!updateCurrentUserError) return

    showToast({
      type: 'error',
      title: t('account:errors.default'),
      description: updateCurrentUserError.message
    })
  }, [updateCurrentUserError, showToast])

  useEffect(() => {
    if (updateCurrentUserStatus === 200) {
      setCurrentUser(userWithAvatar)
    }
  }, [updateCurrentUserStatus, userWithAvatar, setCurrentUser])

  return {
    fetchCurrentUser,
    currentUser,
    fetchCurrentUserLoading,
    updateDeviseCurrentUser,
    updateDeviseCurrentUserLoading,
    updateDeviseCurrentUserStatus,
    updatePassword,
    updatePasswordLoading,
    updatePasswordStatus,
    updateCurrentUser,
    updateCurrentUserLoading,
    updateCurrentUserStatus,
    updateCurrentUserError
  }
}
