type Args = [number, number, number]

export const rgbToHex = ([r, g, b]: Args): string => {
  return (
    '#' +
    [r, g, b]
      .map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      })
      .join('')
  )
}
