import { t } from 'i18next'
import { FormDialog, FormDialogProps } from 'components'
import { useInventoryCustomClaysContext } from 'contexts'
import { useInventoryCustomClayForm } from '../../hooks'
import { InventoryCustomClayFormFields } from '..'

type Props = Omit<FormDialogProps, 'title' | 'content' | 'onSubmit' | 'requestStatus'>

export const CreateInventoryCustomClayFormDialog = ({ onClose, onSuccess, ...rest }: Props) => {
  const { fetchInventoryCustomClays, postInventoryCustomClayStatus } = useInventoryCustomClaysContext()

  const { form, onSubmit } = useInventoryCustomClayForm()

  return (
    <FormDialog
      title={t('inventory-custom-clays:create')}
      submitLabel={t('inventory-custom-clays:add')}
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={fetchInventoryCustomClays}
      requestStatus={postInventoryCustomClayStatus}
      content={<InventoryCustomClayFormFields form={form} />}
      maxWidth="md"
      {...rest}
    />
  )
}
