import { useEffect, useCallback } from 'react'
import { t } from 'i18next'
import { Button, IconButton, MenuItem, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { FieldValues, UseFormReturn, useFieldArray } from 'react-hook-form'

import { useClayFieldsContext } from 'contexts'
import { Input, Select } from 'components'
import { useFinder } from 'hooks'
import { Brand, Clay } from 'models'
import { ClaysAutocomplete } from 'pages/inventory/components'

import { ClayFormValues } from '../types'
import { ColorSwatch, ClayImg, ClaysContainer } from './styles'
import { getMixedColor } from './utils'

const CLAY_INITIAL_VALUES: ClayFormValues = {
  brandId: '',
  clayId: '',
  weight: undefined,
  hexColor: '',
  inventoryClayLotId: ''
}

type Props = {
  form: UseFormReturn<FieldValues, object>
}

export const InventoryCustomClayFormFields = ({ form }: Props) => {
  const { brands, inventoryClays } = useClayFieldsContext()
  const { findRecord: findBrand } = useFinder(brands)
  const { findRecordBy: findInventoryClayBy } = useFinder(inventoryClays)

  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    reset
  } = form

  const {
    fields: clayFields,
    append,
    remove
  } = useFieldArray({
    control: control,
    name: 'clays'
  })

  useEffect(() => {
    append([CLAY_INITIAL_VALUES, CLAY_INITIAL_VALUES])
    return () => reset()
  }, []) // eslint-disable-line

  const values = watch()

  const getSelectedClay = useCallback((brand: Brand, clayId: string) => {
    return brand?.clays?.find(clay => clay.id === clayId)
  }, [])

  const setHexColorFromImage = (selectedClay: Clay, index: number) => {
    setValue(`clays.${index}.hexColor`, selectedClay.hexColor)
  }

  const handleAddClay = () => {
    append(CLAY_INITIAL_VALUES)
  }

  const handleRemoveClay = (clayIndex: number) => {
    if (clayIndex < 2) return
    remove(clayIndex)
  }

  const getInventoryClayLotOptions = useCallback(
    (index: number) => {
      const selectedInventoryClay = findInventoryClayBy('clayId', values.clays[index].clayId)
      return selectedInventoryClay?.inventoryClayLots?.map(({ id, code }) => ({ id, code }))
    },
    [values.clays, findInventoryClayBy]
  )

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {clayFields.map((field, index) => {
        const selectedBrand = findBrand(values.clays[index].brandId)
        const selectedClay = getSelectedClay(selectedBrand, values.clays[index].clayId)

        return (
          <ClaysContainer key={field.id}>
            <Select
              label={t('brand:resource')}
              placeholder={t('brand:resource')}
              control={control}
              variant="standard"
              errorMessage={errors.clays?.[index]?.brandId?.message}
              {...register(`clays.${index}.brandId`)}
            >
              {brands?.map(brand => (
                <MenuItem value={brand.id} key={brand.id}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
            <ClaysAutocomplete
              name={`clays.${index}.clayId`}
              control={control}
              brandId={values.clays[index].brandId}
              errorMessage={errors.clays?.[index]?.clayId?.message}
            />
            <Input
              type="number"
              min={0}
              label={`${t('inventory-custom-clay:weight')} (g)`}
              placeholder={`${t('inventory-custom-clay:weight')} (g)`}
              control={control}
              variant="standard"
              errorMessage={errors.clays?.[index]?.weight?.message}
              {...register(`clays.${index}.weight`)}
            />
            <Select
              label={t('inventory-clay-lot:resource-short')}
              placeholder={t('inventory-clay-lot:resource-short')}
              control={control}
              variant="standard"
              emptyMessage={t('inventory-clays:no-options')}
              errorMessage={errors.clays?.[index]?.inventoryClayLotId?.message}
              disabled={!findInventoryClayBy('clayId', selectedClay?.id)}
              {...register(`clays.${index}.inventoryClayLotId`)}
            >
              {getInventoryClayLotOptions(index)?.map(lot => (
                <MenuItem value={lot.id} key={lot.id}>
                  {lot.code}
                </MenuItem>
              ))}
            </Select>
            {selectedClay && (
              <>
                <ClayImg
                  src={selectedClay.photoUrl}
                  onLoad={() => setHexColorFromImage(selectedClay, index)}
                />
                <ColorSwatch hexColor={values.clays[index].hexColor} />
              </>
            )}
            <IconButton
              aria-label="share"
              onClick={() => handleRemoveClay(index)}
              sx={{ width: 36, height: 36 }}
            >
              <Close />
            </IconButton>
          </ClaysContainer>
        )
      })}

      <Box display="flex">
        <Input
          type="text"
          label={t('inventory-custom-clay:name')}
          placeholder={t('inventory-custom-clay:name')}
          control={control}
          variant="standard"
          errorMessage={errors.name?.message as string}
          styles={{ width: '50%' }}
          {...register(`name`)}
        />

        <Box display="flex" alignItems="center" marginLeft={4}>
          <ColorSwatch hexColor={getMixedColor(values.clays)} />
        </Box>
      </Box>

      <Button onClick={handleAddClay} sx={{ alignSelf: 'baseline', mt: 1 }}>
        {t('inventory-custom-clay:add-clay')}
      </Button>
    </Box>
  )
}
