import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import { colors, variables } from 'styles'

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const FormContainer = styled.div`
  width: 100%;
  min-width: 550px;
  display: flex;
  justify-content: center;
  margin-top: -${variables.topbarHeight}px;

  @media (max-width: 550px) {
    width: 100%;
    min-width: unset;
  }
`
export const Form = styled.form`
  width: 400px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.custom.card.bg};
`
export const Title = styled.h1`
  color: ${props => props.theme.custom.accent};
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
`
export const Link = styled(RouterLink)`
  margin-top: 8px;
  font-size: 14px;
  color: ${colors.gray_500};

  &:hover {
    text-decoration: underline;
  }
`
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
