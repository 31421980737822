import { createContext, useContext } from 'react'

import { useProjectsController } from 'controllers'

export const ProjectsContext = createContext<ReturnType<typeof useProjectsController> | null>(null)

export const useProjectsContext = () => {
  const context = useContext(ProjectsContext)
  const errorMessage = 'useProjectsContext must be used within a ProjectsProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
