import styled from 'styled-components'
import { ImageOutlined } from '@mui/icons-material'

import { BREAKPOINTS } from 'styles/variables'

export const ProjectPhotoEmpty = styled.div`
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ProjectPhotoEmptyIcon = styled(ImageOutlined)`
  width: auto;
  height: 80%;
  opacity: 0.2;
`
export const cardStyles = {
  width: 'calc(33.333% - 8px)',
  margin: '4px',

  [`@media (max-width: ${BREAKPOINTS.xs}px)`]: {
    width: 'calc(50% - 8px)'
  }
}
