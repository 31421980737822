import { Link } from 'react-router-dom'
import { Card, CardMedia, CardContent } from '@mui/material'

import { TypographyEllipsis } from 'components'
import { Project } from 'models'

import { cardStyles, ProjectPhotoEmpty, ProjectPhotoEmptyIcon } from './styles'

type Props = {
  project: Project
}

export const ProjectListCard = ({ project }: Props) => {
  const projectPhoto = project.projectPhotos?.[0]

  return (
    <Card sx={cardStyles} key={project.id} component={Link} to={`/projects/${project.id}`}>
      {projectPhoto ? (
        <CardMedia component="img" height="140" image={projectPhoto?.imageUrl} alt={project.title} />
      ) : (
        <ProjectPhotoEmpty>
          <ProjectPhotoEmptyIcon />
        </ProjectPhotoEmpty>
      )}
      <CardContent>
        <TypographyEllipsis variant="h5" lineClamp={2}>
          {project.title}
        </TypographyEllipsis>
      </CardContent>
    </Card>
  )
}
