import { useApi } from 'hooks'
import { Brand, InventoryClay } from 'models'
import { BrandsEndpoint, InventoryClaysEndpoint } from 'routes/locations'

export const useClayFieldsController = () => {
  const [fetchBrands, brands] = useApi<Brand[]>(BrandsEndpoint, 'GET')
  const [fetchInventoryClaysCall, inventoryClays] = useApi<InventoryClay[]>(InventoryClaysEndpoint, 'GET')

  const fetchInventoryClays = () => {
    fetchInventoryClaysCall({}, { page: 1, per: 1000 })
  }

  return {
    fetchBrands,
    fetchInventoryClays,
    brands,
    inventoryClays
  }
}
