import { ReactNode } from 'react'

import { InventoryClaysContext } from 'contexts'
import { useInventoryClaysController } from 'controllers'

type Props = {
  children: ReactNode
}

export const InventoryClaysProvider = ({ children }: Props) => {
  return (
    <InventoryClaysContext.Provider value={useInventoryClaysController()}>
      {children}
    </InventoryClaysContext.Provider>
  )
}
