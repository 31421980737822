import { useState } from 'react'
import { t } from 'i18next'
import { Button } from '@mui/material'

import { useInventoryClayContext } from 'contexts'
import { CreateInventoryClayFormDialog } from 'pages/inventory/components'

export const CreateInventoryClayLotButtonForm = () => {
  const { fetchInventoryClay, inventoryClay } = useInventoryClayContext()
  const [isFormDialogOpen, setIsFormDialog] = useState<boolean>(false)

  const handleButtonClick = () => {
    setIsFormDialog(true)
  }

  const handleClose = () => {
    setIsFormDialog(false)
  }

  if (!inventoryClay) return null

  const initialValues = {
    brandId: inventoryClay.clay.brandId,
    clayId: inventoryClay.clay.id
  }

  return (
    <>
      <Button onClick={handleButtonClick} sx={{ alignSelf: 'flex-end' }}>
        {t('inventory-clay-lots:create')}
      </Button>

      <CreateInventoryClayFormDialog
        open={isFormDialogOpen}
        onClose={handleClose}
        onSuccess={fetchInventoryClay}
        initialValues={initialValues}
      />
    </>
  )
}
