import { DeleteOutlined } from '@mui/icons-material'
import { t } from 'i18next'

import { MenuItemProps } from 'components'

export const inventoryClayCardMenuItems: () => MenuItemProps[] = () => [
  {
    id: 'delete',
    icon: DeleteOutlined,
    label: t('actions.delete'),
    onClick: () => {}
  }
]
