import styled from 'styled-components'

export const ImgWrapper = styled.figure`
  height: 350px;
  width: 70%;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  margin: 0 auto;
`
export const HeroImg = styled.img`
  width: 100%;
  position: absolute;
  top: -160px;
`
export const Img = styled.img`
  width: 80%;
  margin: 0 auto;
  border-radius: 16px;
`
