import { BREAKPOINTS } from 'styles/variables'

export const inventoriableClayCardMobileStyles = {
  width: 'calc(20% - 8px)',

  [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
    width: 'calc(25% - 8px)'
  },
  [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
    width: 'calc(33.333% - 8px)'
  },
  [`@media (max-width: ${BREAKPOINTS.xs}px)`]: {
    width: 'calc(50% - 8px)'
  }
}
