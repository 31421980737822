import { BREAKPOINTS } from 'styles/variables'

export const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  alignSelf: 'baseline',
  m: '32px 0',

  [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
    width: '80%',
    alignSelf: 'center'
  }
}
