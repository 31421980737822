import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import { PageContainer } from 'components'
import {
  InventoryClaysProvider,
  InventoryCustomClaysProvider,
  InventoryCustomClayProvider,
  InventoryClayProvider
} from 'providers'

import { InventoryClaysNav } from './components'
import { INVENTORY_PAGE_MAIN_BUTTONS, InventoryPageMainButtons } from './constants'

const InventoryPageFC = () => {
  const [pageMainButton, setPageMainButton] = useState<InventoryPageMainButtons>()
  const PageMainButton = INVENTORY_PAGE_MAIN_BUTTONS[pageMainButton]

  return (
    <PageContainer flexColumn>
      {PageMainButton && <PageMainButton />}

      <InventoryClaysNav />

      <Outlet context={{ pageMainButton, setPageMainButton }} />
    </PageContainer>
  )
}

export const InventoryPage = () => (
  <InventoryClaysProvider>
    <InventoryClayProvider>
      <InventoryCustomClayProvider>
        <InventoryCustomClaysProvider>
          <InventoryPageFC />
        </InventoryCustomClaysProvider>
      </InventoryCustomClayProvider>
    </InventoryClayProvider>
  </InventoryClaysProvider>
)
