import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'

import { InventoryCustomClayContext } from 'contexts'
import { useInventoryCustomClayController } from 'controllers'

type Props = {
  children: ReactNode
}

export const InventoryCustomClayProvider = ({ children }: Props) => {
  const { inventoryCustomClayId } = useParams()

  return (
    <InventoryCustomClayContext.Provider value={useInventoryCustomClayController(inventoryCustomClayId)}>
      {children}
    </InventoryCustomClayContext.Provider>
  )
}
