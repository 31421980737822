import { PageContainer } from 'components'
import { Link, Outlet } from 'react-router-dom'
import { Button } from '@mui/material'

import { Aside, linkStyles } from './styles'

export const AdminHomePage = () => {
  return (
    <PageContainer flex fullWidth styles={{ padding: '24px' }}>
      <Aside>
        <nav>
          <ul>
            <li>
              <Button to="" component={Link} sx={linkStyles}>
                Brands
              </Button>
            </li>
            <li>
              <Button to="clays" component={Link} sx={linkStyles}>
                Clays
              </Button>
            </li>
          </ul>
        </nav>
      </Aside>

      <Outlet />
    </PageContainer>
  )
}
