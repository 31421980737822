import { useState, useEffect } from 'react'
import { t } from 'i18next'
import { Button } from '@mui/material'

import { useInventoryCustomClayContext } from 'contexts'

import { DeleteInventoryCustomClayDialog } from '..'

export const DeleteInventoryCustomClayButtonForm = () => {
  const { inventoryCustomClay, deleteInventoryCustomClay } = useInventoryCustomClayContext()
  const [isFormDialogOpen, setIsFormDialog] = useState<boolean>(false)

  const handleButtonClick = () => {
    setIsFormDialog(true)
  }

  const handleClose = () => {
    setIsFormDialog(false)
  }

  useEffect(() => {
    return () => setIsFormDialog(false)
  }, [])

  return (
    <>
      <Button onClick={handleButtonClick} sx={{ alignSelf: 'flex-end' }}>
        {t('inventory-custom-clay:delete.title')}
      </Button>

      <DeleteInventoryCustomClayDialog
        open={isFormDialogOpen}
        onClose={handleClose}
        onSubmit={deleteInventoryCustomClay}
        projectInventoryClayLotCount={inventoryCustomClay?.projectInventoriableClays.length}
      />
    </>
  )
}
