import { Typography } from '@mui/material'
import { t } from 'i18next'

import { ConfirmDialog, ConfirmDialogProps } from 'components'

type Props = Omit<ConfirmDialogProps, 'title' | 'content'>

export const DeleteProjectDialog = (props: Props) => {
  return (
    <ConfirmDialog
      submitLabel="Delete"
      title={t('project:delete.title')}
      content={<Typography>{t('project:delete.description')}</Typography>}
      {...props}
    />
  )
}
