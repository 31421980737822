import styled from 'styled-components'
import { Button as MaterialButton, Input } from '@mui/material'

export const ProjectContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 60vw;
`
export const Aside = styled.aside`
  margin-right: 24px;
  max-width: 270px;
  padding: 4px 8px;
`
export const ClayListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
`
export const ProjectPhotoEmptyState = styled(MaterialButton)`
  width: 100%;
  height: 200px;
  padding: 24px;
  display: flex;
  border-radius: 8px;
  position: relative;
  border: 2px solid ${({ theme }) => theme.custom.accent};
`
export const FileInput = styled(Input)`
  &.MuiInput-root {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;

    .MuiInput-input {
      width: inherit;
      height: inherit;
    }
  }
`
export const ProjectPhoto = styled.img`
  width: 100%;
  margin-bottom: 8px;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
`
