import { ReactNode } from 'react'

import { ClayFieldsContext } from 'contexts'
import { useClayFieldsController } from 'controllers'

type Props = {
  children: ReactNode
}

export const ClayFieldsProvider = ({ children }: Props) => {
  return <ClayFieldsContext.Provider value={useClayFieldsController()}>{children}</ClayFieldsContext.Provider>
}
