import { useRef, useCallback, useState } from 'react'
import { t } from 'i18next'
import { Link, useLocation } from 'react-router-dom'
import { Menu, MenuItem, ListItemIcon } from '@mui/material'
import { Logout, AdminPanelSettingsOutlined } from '@mui/icons-material'
import { SignOutEndpoint } from 'routes'
import { useCurrentUserContext } from 'contexts'
import { useAuth } from 'hooks'

import { ReactComponent as Logo } from 'assets/images/logo.svg'
import FlowerAvatarPlaceholder from 'assets/images/flower.png'

import { APP_MENU_ITEMS, PUBLIC_APP_MENU_ITEMS } from './constants'
import { Container, MenuContainer, LogoLink, UnconfirmedEmailBanner } from './styles'

export const Topbar = () => {
  const { currentUser } = useCurrentUserContext()
  const { firstName, avatarThumbUrl } = currentUser || {}

  const [signOut] = useAuth(SignOutEndpoint, 'DELETE')
  const { pathname } = useLocation()

  const menuTriggerRef = useRef()
  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleMenu = useCallback(() => {
    setMenuAnchor(prevState => (prevState ? null : menuTriggerRef.current))
  }, [])

  const handleSignOut = useCallback(() => {
    signOut()
  }, [signOut])

  const authLinks =
    pathname === '/sign-in' ? (
      <Link to="/sign-up">{t('sign-up:title')}</Link>
    ) : (
      <Link to="/sign-in">{t('sign-in:title')}</Link>
    )

  const publicMenu = (
    <>
      {PUBLIC_APP_MENU_ITEMS().map(({ to, label }) => (
        <Link to={to} key={to} style={{ marginRight: 24 }}>
          {label}
        </Link>
      ))}
      {authLinks}
    </>
  )

  return (
    <Container>
      <LogoLink to="/">
        <Logo />
      </LogoLink>

      <nav>
        <div>
          {currentUser?.id ? (
            <MenuContainer ref={menuTriggerRef} $hasAvatar={!!avatarThumbUrl} onClick={handleMenu}>
              {avatarThumbUrl ? (
                <img src={avatarThumbUrl} alt={firstName} />
              ) : (
                <img src={FlowerAvatarPlaceholder} alt={currentUser.firstName} />
              )}

              <Menu
                anchorEl={menuAnchor}
                open={!!menuAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                {APP_MENU_ITEMS().map(({ to, icon: Icon, label }) => (
                  <MenuItem component={Link} to={to} key={to}>
                    <ListItemIcon>
                      <Icon fontSize="small" />
                    </ListItemIcon>
                    {label}
                  </MenuItem>
                ))}

                {currentUser.admin && (
                  <MenuItem component={Link} to="/admin">
                    <ListItemIcon>
                      <AdminPanelSettingsOutlined fontSize="small" />
                    </ListItemIcon>
                    {t('pages.admin')}
                  </MenuItem>
                )}

                <MenuItem onClick={handleSignOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t('sign-in:sign-out')}
                </MenuItem>
              </Menu>
            </MenuContainer>
          ) : (
            publicMenu
          )}
        </div>
      </nav>

      {currentUser?.id && !currentUser?.confirmedAt && (
        <UnconfirmedEmailBanner>{t('sign-up:confirm-email:alert')}</UnconfirmedEmailBanner>
      )}
    </Container>
  )
}
