import styled from 'styled-components'

export const InputGroup = styled.div`
  display: flex;
  gap: 8px;

  > div {
    flex: 1;
  }

  & + & {
    margin-top: 16px;
  }
`
