import { useEffect } from 'react'
import { t } from 'i18next'
import { Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { PageContainer } from 'components'
import { useToastContext, useCurrentUserContext } from 'contexts'
import { useApi } from 'hooks'
import { ConfirmEmailEndpoint } from 'routes/locations'
import { buildErrorMessage } from 'utils'

export const ConfirmEmail = () => {
  const [confirmEmail, userWithConfirmedEmail, error, , status] = useApi(ConfirmEmailEndpoint)
  const { showToast } = useToastContext()
  const { setCurrentUser } = useCurrentUserContext()
  const [searchParams] = useSearchParams()
  const confirmationToken = searchParams.get('confirmation_token')

  useEffect(() => {
    confirmEmail({}, { confirmation_token: confirmationToken })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (status !== 200 || !userWithConfirmedEmail) return

    setCurrentUser(userWithConfirmedEmail)

    showToast({
      type: 'success',
      title: t('sign-up:confirm-email:success.title'),
      description: t('sign-up:confirm-email:success.description')
    })
  }, [status, userWithConfirmedEmail, showToast, setCurrentUser])

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: t('sign-up:confirm-email:errors'),
      description: buildErrorMessage(error)
    })
  }, [error, showToast])

  return (
    <PageContainer hCenter>
      <Typography variant="body1" color="text.secondary" textAlign="center" mt={8}>
        {!status && t('sign-up:confirm-email:loading')}
        {status === 200 && t('sign-up:confirm-email:success.title')}
        {status >= 400 && t('sign-up:confirm-email:errors')}
      </Typography>
    </PageContainer>
  )
}
