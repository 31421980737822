import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { shade } from 'polished'

import { colors, variables, fadeSlideAnimation } from 'styles'

export const Container = styled.div`
  width: ${variables.containerWidth}px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  flex: 1;
  position: relative;

  @media (max-width: 960px) {
    width: 100%;
    padding: 0 32px;
  }
`
export const FormsContainer = styled.div`
  width: 100%;
  margin: 72px auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    form {
      margin-bottom: 32px;
      width: 80%;
    }
  }
`
export const Form = styled.form`
  width: calc(50% - 24px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  @media (max-width: 960px) {
    width: calc(50% - 12px);
  }
`
export const Title = styled.h1`
  width: 100%;
  color: ${props => props.theme.custom.accent};
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-top: 32px;
  text-align: center;
`
export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    margin: 0;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }

    input {
      width: 50%;
    }
  }
`
export const Link = styled(RouterLink)`
  margin-top: 8px;
  font-size: 14px;
  color: ${colors.gray_500};

  &:hover {
    text-decoration: underline;
  }
`
export const AvatarInput = styled.div`
  margin-top: 48px;
  position: relative;
  align-self: center;

  img {
    width: 185px;
    height: 185px;
    border-radius: 50%;
    object-fit: cover;
    animation: ${fadeSlideAnimation({ dirX: 'left', dirY: 'bottom' })} 0.5s ease-in;

    &.reset-animation {
      animation: unset;
    }
  }

  label {
    width: 48px;
    height: 48px;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    background: ${({ theme }) => theme.custom.button.bg};
    transition: background-color 0.1s;

    &:hover {
      background: ${({ theme }) => shade(0.1, theme.custom.accent)};
    }

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: auto;
      color: ${({ theme }) => theme.custom.button.text};
    }
  }

  @media (max-width: 670px) {
    margin-bottom: 24px;
  }
`
