import { ChangeEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Pagination as MaterialPagination, PaginationProps } from '@mui/material'

type Props = {
  totalPages: number
} & Omit<PaginationProps, 'page' | 'onChange'>

export const Pagination = ({ totalPages, ...rest }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = parseInt(searchParams.get('page')) || 1

  const handlePagination = (_: ChangeEvent<unknown>, selectedPage: number) => {
    searchParams.set('page', selectedPage.toString())
    setSearchParams(searchParams)
  }

  return (
    totalPages > 1 && (
      <MaterialPagination
        page={page}
        count={totalPages}
        onChange={handlePagination}
        variant="outlined"
        color="primary"
        size="large"
        sx={{ mt: 4, alignSelf: 'center' }}
        {...rest}
      />
    )
  )
}
