import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { t } from 'i18next'

import { EmptyState, Pagination } from 'components'
import { useInventoryClaysContext } from 'contexts'
import { usePagination } from 'hooks'
import { pageEmptyStateStyles } from 'styles'

import { ClayListContainer } from '../styles'
import { PageMainButtonProps, PageMainButtonOutletContextProps } from '../types'
import { InventoryClayCard } from './components'

type InventoryClaysPageProps = PageMainButtonProps

export const InventoryClaysPage = ({ pageMainButton }: InventoryClaysPageProps) => {
  const { fetchInventoryClays, inventoryClays, fetchInventoryClaysMeta } = useInventoryClaysContext()
  const { setPageMainButton } = useOutletContext<PageMainButtonOutletContextProps>()

  const { page } = usePagination()
  const { totalPages } = fetchInventoryClaysMeta

  useEffect(() => {
    fetchInventoryClays({}, { page })
  }, [page]) // eslint-disable-line

  useEffect(() => {
    setPageMainButton(pageMainButton)
  }, []) // eslint-disable-line

  if (!inventoryClays) {
    return <>{t('loading')}</>
  }

  return (
    <>
      <ClayListContainer>
        {inventoryClays.length ? (
          inventoryClays.map(inventoryClay => (
            <InventoryClayCard inventoryClay={inventoryClay} key={inventoryClay.id} />
          ))
        ) : (
          <EmptyState message={t('inventory-clays:empty-state')} styles={pageEmptyStateStyles} />
        )}
      </ClayListContainer>

      <Pagination totalPages={totalPages} />
    </>
  )
}
