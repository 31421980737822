import { createContext, useContext } from 'react'

import { useInventoryClaysController } from 'controllers'

export const InventoryClaysContext = createContext<ReturnType<typeof useInventoryClaysController> | null>(
  null
)

export const useInventoryClaysContext = () => {
  const context = useContext(InventoryClaysContext)
  const errorMessage = 'useInventoryClaysContext must be used within a InventoryClaysProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
