import { t } from 'i18next'
import { Button, Box } from '@mui/material'

import { InventoryCustomClayFormFields } from '..'
import { useInventoryCustomClayForm } from '../../hooks'
import { buttonBoxStyles } from './styles'

export const CreateInventoryCustomClayForm = () => {
  const { form, onSubmit } = useInventoryCustomClayForm()

  return (
    <form onSubmit={onSubmit}>
      <Box sx={buttonBoxStyles}>
        <Button type="submit">{t('inventory-custom-clays:save')}</Button>
      </Box>

      <InventoryCustomClayFormFields form={form} />
    </form>
  )
}
