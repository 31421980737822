import { useCallback, useEffect } from 'react'
import { Button, MenuItem, SelectChangeEvent } from '@mui/material'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { t } from 'i18next'

import { useClayFieldsContext } from 'contexts'
import { Input, Select } from 'components'
import { useApi, useFinder } from 'hooks'
import { InventoryCustomClay, Project } from 'models'
import { ClaysAutocomplete } from 'pages/inventory/components'
import { InventoryCustomClaysEndpoint } from 'routes'
import { ClayOption } from 'pages/inventory/components/ClaysAutocomplete'

import {
  ClayContainer,
  SelectGroup,
  InputGroup,
  descriptionInputStyles,
  customClayDescriptionInputStyles
} from './styles'
import { ProjectFormValues } from '../ProjectFormDialog/schema'
import { getProjectInventoriableClayInitialValues } from './utils'

const projectInventoryCustomClayInitialValues = {
  weight: undefined,
  description: '',
  inventoriableClayId: '',
  inventoriableClayType: 'InventoryCustomClay'
}
const projectInventoryClayInitialValues = {
  ...projectInventoryCustomClayInitialValues,
  brandId: '',
  inventoryClayId: '',
  inventoriableClayType: 'InventoryClayLot'
}

type Props = {
  form: UseFormReturn<Pick<ProjectFormValues, 'clays'>>
  project?: Project
}

export const ProjectInventoriableClayFields = ({ form, project }: Props) => {
  const { brands, inventoryClays } = useClayFieldsContext()
  const [fetchInventoryCustomClays, inventoryCustomClays] = useApi<InventoryCustomClay[]>(
    InventoryCustomClaysEndpoint
  )
  const { findRecord: findInventoryClays } = useFinder(inventoryClays)

  useEffect(() => {
    fetchInventoryCustomClays()
  }, []) // eslint-disable-line

  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors }
  } = form

  const {
    fields: clayFields,
    append,
    remove
  } = useFieldArray({
    control: control,
    name: 'clays'
  })

  useEffect(() => {
    if (!project?.projectInventoriableClays) return

    const initialClayValues = getProjectInventoriableClayInitialValues(project?.projectInventoriableClays)
    append(initialClayValues)

    return () => remove()
  }, []) // eslint-disable-line

  const values = watch()

  const handleAddClay = useCallback(() => {
    append(projectInventoryClayInitialValues)
  }, [append])

  const handleAddCustomClay = useCallback(() => {
    append(projectInventoryCustomClayInitialValues)
  }, [append])

  const handleRemoveClay = useCallback(
    index => {
      remove(index)
    },
    [remove]
  )

  const handleSelectBrand = (e: SelectChangeEvent<string>, index: number) => {
    setValue(`clays.${index}.brandId`, e.target.value)
    setValue(`clays.${index}.inventoryClayId`, '')
    setValue(`clays.${index}.inventoriableClayId`, '')
  }

  const handleSelectInventoryClay = (option: ClayOption, index: number) => {
    setValue(`clays.${index}.inventoryClayId`, option?.id)
    setValue(`clays.${index}.inventoriableClayId`, '')
  }

  const getInventoryClayLotOptions = useCallback(
    (index: number) => {
      const selectedInventoryClay = findInventoryClays(values.clays[index].inventoryClayId)
      return selectedInventoryClay?.inventoryClayLots?.map(({ id, code }) => ({ id, code }))
    },
    [values.clays, findInventoryClays]
  )

  const getInventoryCustomClayOptions = useCallback(() => {
    return inventoryCustomClays?.map(({ id, name }) => ({ id, name })) || []
  }, [inventoryCustomClays])

  return (
    <>
      <Button onClick={handleAddClay}>{t('inventory-clays:add')}</Button>
      <Button onClick={handleAddCustomClay} sx={{ ml: 1 }}>
        {t('inventory-custom-clays:add')}
      </Button>

      {clayFields.map((field, index) => {
        const { inventoriableClayType } = field
        const fieldError = errors.clays?.[index]
        return (
          <ClayContainer key={field.id}>
            {inventoriableClayType === 'InventoryClayLot' ? (
              <>
                <SelectGroup>
                  <Select
                    label="Brand"
                    control={control}
                    variant="standard"
                    errorMessage={fieldError?.brandId?.message}
                    {...register(`clays.${index}.brandId`)}
                    onChange={(e: SelectChangeEvent<string>) => handleSelectBrand(e, index)}
                  >
                    {brands?.map(brand => (
                      <MenuItem value={brand.id} key={brand.id}>
                        {brand.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <ClaysAutocomplete
                    name={`clays.${index}.inventoryClayId`}
                    defaultValue={values.clays[index].inventoryClayId || ''}
                    control={control}
                    brandId={values.clays[index].brandId}
                    errorMessage={fieldError?.inventoryClayId?.message}
                    onChange={(_, option: ClayOption) => handleSelectInventoryClay(option, index)}
                    inventoryOnly
                  />
                </SelectGroup>

                <InputGroup>
                  <Input
                    type="text"
                    label="Description"
                    placeholder="Description"
                    control={control}
                    variant="standard"
                    styles={descriptionInputStyles}
                    {...register(`clays.${index}.description`)}
                  />
                  <Input
                    type="number"
                    label="Weight"
                    placeholder="Weight"
                    control={control}
                    variant="standard"
                    errorMessage={fieldError?.weight?.message}
                    styles={{ flex: 1 }}
                    {...register(`clays.${index}.weight`)}
                  />
                  <Select
                    label="Lot"
                    control={control}
                    variant="standard"
                    errorMessage={fieldError?.inventoriableClayId?.message}
                    styles={{ flex: 1 }}
                    {...register(`clays.${index}.inventoriableClayId`)}
                  >
                    {getInventoryClayLotOptions(index)?.map(lot => (
                      <MenuItem value={lot.id} key={lot.id}>
                        {lot.code}
                      </MenuItem>
                    ))}
                  </Select>
                </InputGroup>
              </>
            ) : (
              <>
                <SelectGroup>
                  <Select
                    label="Color"
                    control={control}
                    variant="standard"
                    errorMessage={fieldError?.inventoriableClayId?.message}
                    {...register(`clays.${index}.inventoriableClayId`)}
                  >
                    {getInventoryCustomClayOptions().map(inventoryCustomClay => (
                      <MenuItem value={inventoryCustomClay.id} key={inventoryCustomClay.id}>
                        {inventoryCustomClay.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Input
                    type="number"
                    label="Weight"
                    placeholder="Weight"
                    control={control}
                    variant="standard"
                    errorMessage={fieldError?.weight?.message}
                    {...register(`clays.${index}.weight`)}
                  />
                </SelectGroup>
                <InputGroup>
                  <Input
                    type="text"
                    label="Description"
                    placeholder="Description"
                    control={control}
                    variant="standard"
                    styles={customClayDescriptionInputStyles}
                    {...register(`clays.${index}.description`)}
                  />
                </InputGroup>
              </>
            )}

            <Button
              size="small"
              onClick={() => handleRemoveClay(index)}
              sx={{ mt: '0.4rem', alignSelf: 'flex-end', fontSize: '10px' }}
            >
              {t('actions.remove')}
            </Button>
          </ClayContainer>
        )
      })}
    </>
  )
}
