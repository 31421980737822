import { useState, useEffect } from 'react'
import { Button } from '@mui/material'

import { ConfirmDialogProps } from 'components'

import { SetClaysHexColorDialog } from '..'

type Props = {
  claysCount: number
} & Pick<ConfirmDialogProps, 'onSubmit' | 'onSuccess'>

export const SetClaysHexColorButtonForm = ({ onSubmit, onSuccess, claysCount }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleButtonClick = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  useEffect(() => {
    return () => setIsDialogOpen(false)
  }, [])

  return (
    <>
      <Button onClick={handleButtonClick} disabled={!claysCount} sx={{ alignSelf: 'flex-end' }}>
        Set Clays Hex Color
      </Button>

      <SetClaysHexColorDialog
        open={isDialogOpen}
        onClose={handleClose}
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        claysCount={claysCount}
      />
    </>
  )
}
