import { useSearchParams } from 'react-router-dom'

const DEFAUlT_PAGE = 1

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = parseInt(searchParams.get('page')) || DEFAUlT_PAGE

  const setPage = pageParam => {
    searchParams.set('page', pageParam.toString())
    setSearchParams(searchParams)
  }

  return {
    page,
    setPage
  }
}
