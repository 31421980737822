import { useEffect, useState } from 'react'
import { changeLanguage } from 'i18next'

import { LANGUAGE_KEY } from 'hooks/constants'
import { DEFAULT_LANGUAGE, Language } from './constants'

export const useLanguage = () => {
  const defaultValue = (localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE) as Language
  const [language, setLanguage] = useState<Language>(defaultValue)

  useEffect(() => {
    if (!language) return

    localStorage.setItem(LANGUAGE_KEY, language)
    changeLanguage(language)
  }, [language])

  return {
    language,
    setLanguage
  }
}
