export const colors = {
  offWhite: '#fafafa',
  offYellow: '#faf9f8',
  offBlack: '#10131A',
  accent: '#a4124d',

  gray_100: '#eee',
  gray_300: '#a7a7b3',
  gray_500: '#7A7A82',
  gray_700: '#333336',
  gray_800: '#222938',

  info: { text: '#ebf8ff', bg: '#3172b7' },
  success: { text: '#e6fff1', bg: '#2e656a' },
  error: { text: '#fddede', bg: '#c53030' },

  shadow_200: 'rgba(0, 0, 0, 0.2)',
  shadow_250: 'rgba(0, 0, 0, 0.26)',
  shadow_700: 'rgba(0, 0, 0, 0.7)',
  accentShadow: 'rgba(164, 18, 77, 0.05)',

  transparent: 'rgba(0, 0, 0, 0)',

  green: '#25e651',
  yellow: '#e6b30e',
  red: '#e60217',

  lightGreen: 'rgba(215, 255, 189, 0.7)'
}

export const alertColors = {
  success: {
    color: 'rgba(165, 220, 134, 1)',
    shadow: 'rgba(165, 220, 134, 0.2)'
  },
  error: {
    color: 'rgba(242, 116, 116, 1)',
    shadow: 'rgba(200, 0, 0, .2)'
  },
  warning: {
    color: 'rgba(248, 187, 134, 1)'
  }
}
