import { ReactNode } from 'react'

import { ProjectContext } from 'contexts'
import { useProjectController } from 'controllers'
import { useParams } from 'react-router-dom'

type Props = {
  children: ReactNode
}

export const ProjectProvider = ({ children }: Props) => {
  const { projectId } = useParams()

  return <ProjectContext.Provider value={useProjectController(projectId)}>{children}</ProjectContext.Provider>
}
