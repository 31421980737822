import { LoadingButton } from '@mui/lab'
import { styled, DefaultTheme } from '@mui/material/styles'
import { CSSObject } from 'styled-components'

type StyledButtonProps = {
  styles?: CSSObject
} & DefaultTheme

export const StyledLoadingButton = styled(LoadingButton)<StyledButtonProps>(({ styles = {} }) => ({
  height: 48,
  ...styles
}))
