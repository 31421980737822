import { useEffect, useRef } from 'react'
import { Box, Divider, Typography } from '@mui/material'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { Brand, Clay } from 'models'
import { BrandsEndpoint, AdminUpdateAllClaysEndpoint } from 'routes/locations'
import { useSetClaysHexColor } from 'tasks'

import { AdminClayCard, SetClaysHexColorButtonForm } from './components'
import { serializeAll } from 'utils'

export type ClayImageRef = {
  id: Id
  image: HTMLImageElement
}

export const AdminClaysPage = () => {
  const [fetchBrands, brands] = useApi<Brand[]>(BrandsEndpoint, 'GET')
  const [updateClays, , updateClaysError, , updateClaysStatus] = useApi<Clay[]>(
    AdminUpdateAllClaysEndpoint,
    'PUT'
  )
  const clayWithoutHexColorRefs = useRef<ClayImageRef[]>([])

  const { getUpdateClaysHexColorPayload } = useSetClaysHexColor(clayWithoutHexColorRefs)
  const { showToast } = useToastContext()

  useEffect(() => {
    fetchBrands()
  }, []) //eslint-disable-line

  const onSubmit = async () => {
    const payload = await getUpdateClaysHexColorPayload()
    return updateClays({ clays: serializeAll(payload) })
  }

  const setRef = (image: HTMLImageElement, clay: Clay) => {
    const isDuplicate = clayWithoutHexColorRefs.current.find(ref => ref.id === clay.id)
    !clay.hexColor && !isDuplicate && clayWithoutHexColorRefs.current.push({ id: clay.id, image })
  }

  useEffect(() => {
    if (!updateClaysStatus) return

    updateClaysStatus === 200 &&
      showToast({
        type: 'success',
        title: 'Clays updated successfully',
        description: 'Clays hex colors have been updated.'
      })
    updateClaysError &&
      updateClaysStatus === 422 &&
      showToast({
        type: 'error',
        title: 'Clays updated successfully',
        description: updateClaysError.message
      })
  }, [updateClaysStatus, updateClaysError, showToast])

  if (!brands) {
    return <>Loading...</>
  }

  return (
    <Box display="flex" flexDirection="column" flex={1} sx={{ ml: 3 }}>
      <Box component="header" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Clays
        </Typography>
        <SetClaysHexColorButtonForm
          onSubmit={onSubmit}
          onSuccess={fetchBrands}
          claysCount={clayWithoutHexColorRefs.current.length}
        />
      </Box>

      {brands.map(brand => (
        <Box key={brand.id} display="flex" flexDirection="column">
          <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
            {brand.name}
          </Typography>
          <Divider />

          <Box display="flex" flexWrap="wrap" sx={{ m: '16px -8px' }}>
            {brand.clays.map(clay => (
              <AdminClayCard
                key={clay.id}
                clay={clay}
                ref={(image: HTMLImageElement) => setRef(image, clay)}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
