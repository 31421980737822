import { forwardRef, Ref } from 'react'
import { t } from 'i18next'
import { Autocomplete, AutocompleteProps, AutocompleteRef } from 'components'

import { getClayColorOptionLabel } from './utils'
import { useClayOptionsLookup } from '.'

type Props = {
  brandId: Id
  inventoryOnly?: boolean
} & Pick<
  AutocompleteProps,
  'name' | 'control' | 'onChange' | 'onBlur' | 'errorMessage' | 'defaultValue' | 'disabled'
>

export const ClaysAutocompleteFC = (
  { brandId, inventoryOnly, defaultValue, ...rest }: Props,
  ref: Ref<AutocompleteRef>
) => {
  const { getClayOptions } = useClayOptionsLookup(inventoryOnly)
  const clayOptions = getClayOptions(brandId)
  const initialValue = clayOptions.find(option => option.id === defaultValue)

  return (
    <Autocomplete
      ref={ref}
      label={t('clay:color')}
      placeholder={t('clay:color')}
      defaultValue={initialValue}
      groupBy={option => option.group}
      getOptionLabel={getClayColorOptionLabel}
      options={clayOptions}
      noOptionsText={t('brands:no-options')}
      {...rest}
    />
  )
}

export const ClaysAutocomplete = forwardRef<AutocompleteRef, Props>(ClaysAutocompleteFC)
