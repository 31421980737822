import { CreateInventoryClayButtonForm } from '../InventoryClaysPage/components'
import { CreateInventoryClayLotButtonForm } from '../InventoryClayPage/components'
import { CreateInventoryCustomClayButtonForm } from '../InventoryCustomClaysPage/components'
import { DeleteInventoryCustomClayButtonForm } from '../InventoryCustomClayPage/components'

export const INVENTORY_PAGE_MAIN_BUTTONS = {
  createInventoryClay: CreateInventoryClayButtonForm,
  createInventoryClayLot: CreateInventoryClayLotButtonForm,
  createInventoryCustomClay: CreateInventoryCustomClayButtonForm,
  deleteInventoryCustomClays: DeleteInventoryCustomClayButtonForm
} as const

export type InventoryPageMainButtons = keyof typeof INVENTORY_PAGE_MAIN_BUTTONS
