import styled from 'styled-components'
import { Button as MaterialButton } from '@mui/material'

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px -4px 0;
`
export const Button = styled(MaterialButton)(() => ({
  '&.MuiButton-root': {
    alignSelf: 'flex-end',
    marginBottom: 4
  }
}))
