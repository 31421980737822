import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import { IconButton as MaterialIconButton, Rating as MaterialRating, RatingProps } from '@mui/material'
import { CampaignOutlined } from '@mui/icons-material/'

import { colors } from 'styles/colors'

export const Container = styled.div`
  right: 20px;
  bottom: 20px;
  position: fixed;
  color: ${({ theme }) => theme.custom.base.text};
  background: ${({ theme }) => theme.custom.base.bg};
`
export const FeedbackrIcon = styled(CampaignOutlined)`
  width: 2rem;
  height: 2rem;
  fill: ${colors.offWhite};
`
export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`
export const Fieldset = styled.div`
  width: 80%;
  display: flex;
  gap: 4px;
`
export const CloseButton = styled(MaterialIconButton)`
  &.MuiIconButton-root {
    top: 12px;
    right: 12px;
    position: absolute;

    svg {
      color: ${({ theme }) => theme.custom.base.text};
    }
  }
`
export const Rating = styled(MaterialRating)<RatingProps & { error: boolean }>`
  &.MuiRating-root {
    margin-bottom: 8px;
    ${({ error }) =>
      error &&
      css`
      svg {
          stroke: ${colors.error.bg};
        }
      }
    `}
  }
`
export const SubmitButton = styled(MaterialIconButton)`
  &.MuiIconButton-root {
    width: 36px;
    height: 36px;
    padding: 8px;
    right: 40px;
    bottom: 36px;
    position: absolute;

    svg {
      margin: 2px 2px 0px 0;
    }
  }
`

export const popoverStyles = {
  width: 350,
  height: 400,
  padding: '16px 0'
}

export const feedbackrIconButtonStyles = {
  background: `${lighten(0.3, colors.accent)}`,
  '&:hover': {
    background: `${lighten(0.25, colors.accent)}`
  }
}
