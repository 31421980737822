import { Box, BoxProps } from '@mui/material'
import { PhotoIcon } from './styles'

export const PhotoPlaceholder = ({ sx }: Pick<BoxProps, 'sx'>) => {
  return (
    <Box sx={sx}>
      <PhotoIcon />
    </Box>
  )
}
