import { createContext, useContext } from 'react'

import { useInventoryClayController } from 'controllers'

export const InventoryClayContext = createContext<ReturnType<typeof useInventoryClayController> | null>(null)

export const useInventoryClayContext = () => {
  const context = useContext(InventoryClayContext)
  const errorMessage = 'useInventoryClayContext must be used within a InventoryClayProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
