import { useEffect } from 'react'
import { t } from 'i18next'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { InventoryClayLot } from 'models'
import { InventoryClayLotEndpoint } from 'routes/locations'

export const useInventoryClayLotController = (inventoryClayLotId?: Id, inventoryClayId?: Id) => {
  const [updateInventoryClayLot, , updateInventoryClayLotError, , updateInventoryClayLotStatus] =
    useApi<InventoryClayLot>(InventoryClayLotEndpoint, 'PUT', { inventoryClayId, inventoryClayLotId })
  const [deleteInventoryClayLot, , deleteInventoryClayLotError, , deleteInventoryClayLotStatus] =
    useApi<InventoryClayLot>(InventoryClayLotEndpoint, 'DELETE', {
      inventoryClayId,
      inventoryClayLotId
    })

  const { showToast } = useToastContext()

  useEffect(() => {
    if (updateInventoryClayLotStatus !== 200) return

    showToast({
      type: 'success',
      title: t('inventory-clay-lot:success:update:title'),
      description: t('inventory-clay-lot:success:update:description')
    })
  }, [updateInventoryClayLotStatus, showToast])

  useEffect(() => {
    if (!updateInventoryClayLotError) return

    showToast({
      type: 'error',
      title: t('inventory-clay-lot:errors:update:title'),
      description: updateInventoryClayLotError.message
    })
  }, [updateInventoryClayLotError, showToast])

  useEffect(() => {
    if (deleteInventoryClayLotStatus !== 204) return

    showToast({
      type: 'success',
      title: t('inventory-clay-lot:success:delete:title'),
      description: t('inventory-clay-lot:success:delete:description')
    })
  }, [deleteInventoryClayLotStatus, showToast])

  useEffect(() => {
    if (!deleteInventoryClayLotError) return

    showToast({
      type: 'error',
      title: t('inventory-clay-lot:errors:delete:title'),
      description: deleteInventoryClayLotError.message
    })
  }, [deleteInventoryClayLotError, showToast])

  return {
    updateInventoryClayLot,
    deleteInventoryClayLot,
    updateInventoryClayLotStatus
  }
}
