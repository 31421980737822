import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material'

import { Button } from 'components'
import { useUserAccountController } from 'controllers'
import { useLanguage } from 'hooks'

import { boxStyles } from './styles'

export const LanguageForm = () => {
  const { t } = useTranslation()
  const { language, setLanguage } = useLanguage()
  const { control, handleSubmit } = useForm({
    defaultValues: { language: language }
  })

  const { updateCurrentUser, updateCurrentUserLoading } = useUserAccountController()

  const onSubmit = data => {
    setLanguage(data.language)
    updateCurrentUser({ user: { language: data.language } })
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={boxStyles}>
      <FormControl>
        <FormLabel id="languages" sx={{ mb: 3 }}>
          <Typography variant="h6" color="text.secondary">
            {t('languages:language')}
          </Typography>
        </FormLabel>

        <Controller
          name="language"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="en" control={<Radio />} label={t('languages:english.long')} />
              <FormControlLabel value="pt" control={<Radio />} label={t('languages:portuguese.long')} />
              <FormControlLabel value="es" control={<Radio />} label={t('languages:spanish.long')} />
            </RadioGroup>
          )}
        />

        <Button
          type="submit"
          label={t('actions.update')}
          loading={updateCurrentUserLoading}
          styles={{ marginTop: '24px' }}
        />
      </FormControl>
    </Box>
  )
}
