import styled from 'styled-components'
import { Box } from '@mui/material'

import { Clay } from 'models'
import { BREAKPOINTS } from 'styles/variables'

export const ColorSwatch = styled.figure<Pick<Clay, 'hexColor'>>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${({ hexColor }) => hexColor};

  @media (max-width: ${BREAKPOINTS.sm}px) {
    order: 2;
  }
`
export const ClayImg = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    order: 1;
  }
`
export const ClaysContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;

  > div {
    flex: 1 0;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    flex-wrap: wrap;
    > div {
      flex: 0 0 calc(50% - 36px);
    }
  }
`
