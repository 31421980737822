import { forwardRef, Ref } from 'react'
import { t } from 'i18next'
import { Autocomplete as MaterialAutocomplete, FormControl, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

import { Container, Error, AlertIcon } from './styles'
import { AutocompleteProps, AutocompleteRef } from './types'

const AutocompleteFC = (
  {
    label,
    name,
    errorMessage,
    placeholder,
    control,
    styles,
    options = [],
    emptyMessage,
    getOptionLabel,
    groupBy,
    renderInput,
    variant = 'standard',
    noOptionsText = t('no-options'),
    ...rest
  }: AutocompleteProps,
  ref: Ref<AutocompleteRef>
) => {
  return (
    <Container styles={styles}>
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <MaterialAutocomplete
              options={options}
              placeholder={placeholder}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              groupBy={groupBy}
              getOptionLabel={getOptionLabel}
              noOptionsText={noOptionsText}
              onChange={(_, option) => field.onChange(option?.id || '')}
              renderInput={
                renderInput
                  ? renderInput
                  : inputParams => (
                      <TextField
                        ref={ref}
                        key={inputParams.id}
                        label={label}
                        variant={variant}
                        name={name}
                        error={Boolean(errorMessage)}
                        {...inputParams}
                        {...field}
                      />
                    )
              }
              {...rest}
            />
          )}
        />
        {errorMessage && (
          <Error title={errorMessage} arrow>
            <AlertIcon color="error" />
          </Error>
        )}
      </FormControl>
    </Container>
  )
}

export const Autocomplete = forwardRef<AutocompleteRef, AutocompleteProps>(AutocompleteFC)
