import { MenuItem } from '@mui/material'
import { t } from 'i18next'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import { useClayFieldsContext } from 'contexts'
import { Select } from 'components'
import { ClaysAutocomplete } from 'pages/inventory/components'

import { InputGroup } from './styles'

type Props = {
  form: UseFormReturn<FieldValues, object>
  disabled?: boolean
}

export const InventoryClayFormFields = ({ form, disabled = false }: Props) => {
  const { brands } = useClayFieldsContext()

  const {
    control,
    formState: { errors },
    watch
  } = form

  const values = watch()

  return (
    <InputGroup>
      <Select
        name="brandId"
        label={t('brand:resource')}
        placeholder={t('brand:resource')}
        control={control}
        variant="standard"
        errorMessage={errors.brandId?.message as string}
        disabled={disabled}
      >
        {brands?.map(brand => (
          <MenuItem value={brand.id} key={brand.id}>
            {brand.name}
          </MenuItem>
        ))}
      </Select>

      <ClaysAutocomplete
        name="clayId"
        defaultValue={values.clayId || ''}
        control={control}
        brandId={values.brandId}
        errorMessage={errors.clayId?.message as string}
        disabled={disabled}
      />
    </InputGroup>
  )
}
