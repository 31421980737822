import { useEffect } from 'react'
import { t } from 'i18next'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { InventoryCustomClay } from 'models'
import { InventoryCustomClaysEndpoint } from 'routes/locations'

export const useInventoryCustomClaysController = () => {
  const { showToast } = useToastContext()

  const [fetchInventoryCustomClays, inventoryCustomClays, , , , fetchInventoryCustomClaysMeta] = useApi<
    InventoryCustomClay[]
    // @ts-ignore
  >(InventoryCustomClaysEndpoint, 'GET', {}, InventoryCustomClay)
  const [postInventoryCustomClay, , postInventoryCustomClayError, , postInventoryCustomClayStatus] =
    useApi<InventoryCustomClay>(InventoryCustomClaysEndpoint, 'POST')

  useEffect(() => {
    if (postInventoryCustomClayStatus !== 200) return

    showToast({
      type: 'success',
      title: t('inventory-custom-clays:success:create:title'),
      description: t('inventory-custom-clays:success:create:description')
    })
  }, [postInventoryCustomClayStatus, showToast])

  useEffect(() => {
    if (!postInventoryCustomClayError) return

    showToast({
      type: 'error',
      title: t('inventory-custom-clays:errors:create:title'),
      description: postInventoryCustomClayError.message
    })
  }, [postInventoryCustomClayError, showToast])

  return {
    inventoryCustomClays,
    fetchInventoryCustomClays,
    postInventoryCustomClay,
    postInventoryCustomClayStatus,
    fetchInventoryCustomClaysMeta
  }
}
