import { Box, Typography } from '@mui/material'
import { t } from 'i18next'

import { ConfirmDialog, ConfirmDialogProps, InfoBox } from 'components'

type Props = {
  inventoryClayLotCount: number
} & Omit<ConfirmDialogProps, 'title' | 'content'>

export const DeleteInventoryClayLotDialog = ({ inventoryClayLotCount, ...rest }: Props) => {
  return (
    <ConfirmDialog
      submitLabel="Delete"
      title={t('inventory-clay-lot:delete.title')}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {!!inventoryClayLotCount && (
            <InfoBox>{t('inventory-clay-lot:delete.alert', { count: inventoryClayLotCount })}</InfoBox>
          )}
          <Typography>{t('inventory-clay-lot:delete.confirm')}</Typography>
        </Box>
      }
      {...rest}
    />
  )
}
