import styled from 'styled-components'

export const LotsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -8px;
`
export const ClayTitle = styled.div`
  align-self: center;
  text-align: center;
  margin: 32px 0;
`
export const ClayImg = styled.img`
  width: 400px;
  align-self: center;
`
