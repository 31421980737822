export const singularize = (word: string) => {
  if (!word) return ''
  const pluralList = Object.keys(SUFFIX_DICTIONARY).join('|')
  const pluralsRegex = new RegExp(`(${pluralList})$`)
  return word.replace(pluralsRegex, (pluralSuffix: string) => SUFFIX_DICTIONARY[pluralSuffix])
}

const SUFFIX_DICTIONARY = {
  ves: 'fe',
  ies: 'y',
  i: 'us',
  zes: 'ze',
  ses: 's',
  es: 'e',
  s: ''
} as const
