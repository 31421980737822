import styled from 'styled-components'

import { Clay } from 'models'

export const cardStyles = {
  width: 'calc(16.6666% - 8px)',
  margin: '4px',
  display: 'flex',
  flexDirection: 'column'
}
export const cardContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1
}

export const ColorSwatch = styled.figure<Pick<Clay, 'hexColor'>>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ hexColor }) => hexColor || '#cdefab'};
`
