import styled from 'styled-components'

import { AddAPhotoOutlined } from '@mui/icons-material'

export const PhotoIcon = styled(AddAPhotoOutlined)`
  &.MuiSvgIcon-root {
    width: 100%;
    height: unset;
    opacity: 0.2;
    fill: ${({ theme }) => theme.custom.accent};
  }
`
