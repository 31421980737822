import { useState, useCallback } from 'react'

import { User } from 'models'
import { CURRENT_USER_KEY } from './constants'

type LocalStorageUserData = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'avatarThumbUrl' | 'admin' | 'confirmedAt'
>

export const useStorageCurrentUser = () => {
  const [userData, setUserData] = useState<LocalStorageUserData>(
    JSON.parse(localStorage.getItem(CURRENT_USER_KEY))
  )

  const setCurrentUser = useCallback(
    user => {
      delete user.email
      delete user.type

      setUserData(user)
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user))
    },
    [setUserData]
  )

  return { currentUser: userData, setCurrentUser }
}
