import { useTransition } from 'react-spring'

import { ToastMessage } from 'hooks'

import { ToastContent } from './ToastContent'
import { Container } from './styles'

type Props = {
  messages: ToastMessage[]
}

export const Toast = ({ messages }: Props) => {
  const messagesWithTransitions = useTransition(messages, {
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0%', opacity: 1 },
    leave: { right: '-120%', opacity: 0 }
  })

  return (
    <Container>
      {messagesWithTransitions(({ ...props }, item) => (
        <ToastContent key={item.id} message={item} style={props} />
      ))}
    </Container>
  )
}
