import Location from 'app-location'
import * as Yup from 'yup'

export const InventoryClayLotsEndpoint: Location = new Location(
  '/inventory_clays/:inventoryClayId/inventory_clay_lots',
  {
    inventoryClayId: Yup.string()
  }
)
export const InventoryClayLotEndpoint: Location = new Location(
  '/inventory_clays/:inventoryClayId/inventory_clay_lots/:inventoryClayLotId',
  {
    inventoryClayId: Yup.string(),
    inventoryClayLotId: Yup.string()
  }
)
