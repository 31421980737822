import { t } from 'i18next'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

import { Nav } from './styles'

export const InventoryClaysNav = () => {
  return (
    <Nav>
      <Button component={Link} to="/inventory">
        {t('inventory-clays:resource', { count: 2 })}
      </Button>
      <Button component={Link} to="custom-clays">
        {t('inventory-custom-clays:resource-short', { count: 2 })}
      </Button>
    </Nav>
  )
}
