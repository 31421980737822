import styled from 'styled-components'
import { inventoriableClayCardMobileStyles } from 'pages/inventory/components/styles'

export const cardStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  ...inventoriableClayCardMobileStyles
}
export const cardPortraitStyles = {
  width: 300,

  ' > div:first-child': {
    flex: 2,

    div: {
      width: '50%',
      height: '50%'
    }
  },
  ' > div:last-child': {
    flex: 3,
    padding: '16px 16px 24px'
  },
  img: {
    height: '50%'
  }
}
export const cardContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&:last-child': {
    padding: '0 16px 16px'
  }
}

export const PhotosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  height: 140px;
`
export const ColorSwatchContainer = styled.div`
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ColorSwatch = styled.figure<{ hex: string }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${({ hex }) => hex};
`
