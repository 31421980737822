import { t } from 'i18next'
import {
  PersonOutlined,
  PaletteOutlined,
  SchoolOutlined,
  InventoryOutlined,
  Inventory2Outlined
} from '@mui/icons-material'

export const PUBLIC_APP_MENU_ITEMS = () => [
  {
    to: '/tutorials',
    label: t('pages.tutorials'),
    icon: SchoolOutlined
  }
]

export const APP_MENU_ITEMS = () => [
  {
    to: '/projects',
    label: t('projects:resource', { count: 2 }),
    icon: InventoryOutlined
  },
  {
    to: '/inventory',
    label: t('pages.inventory'),
    icon: Inventory2Outlined
  },
  {
    to: '/color-mixer',
    label: t('pages.color-mixer'),
    icon: PaletteOutlined
  },
  ...PUBLIC_APP_MENU_ITEMS(),
  {
    to: '/account',
    label: t('account:resource'),
    icon: PersonOutlined
  }
]
