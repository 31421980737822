import { Routes as RoutesList, Route } from 'react-router-dom'
import { RouteManager } from '.'

import {
  Home,
  SignIn,
  SignUp,
  ConfirmEmail,
  ResetPassword,
  ForgotPassword,
  UserAccountPage,
  ProjectsPage,
  ProjectPage,
  InventoryPage,
  TutorialsPage,
  ColorMixerPage,
  InventoryClayPage,
  InventoryClaysPage,
  InventoryCustomClayPage,
  InventoryCustomClaysPage,
  AdminHomePage,
  AdminClaysPage
} from 'pages'

export const Routes = () => {
  return (
    <RoutesList>
      <Route path="/" element={<RouteManager isPublic />}>
        <Route index element={<Home />} />
      </Route>

      <Route path="/sign-in" element={<RouteManager auth />}>
        <Route index element={<SignIn />} />
      </Route>
      <Route path="/sign-up" element={<RouteManager auth />}>
        <Route index element={<SignUp />} />
      </Route>
      <Route path="/forgot-password" element={<RouteManager auth />}>
        <Route index element={<ForgotPassword />} />
      </Route>
      <Route path="/reset-password" element={<RouteManager auth />}>
        <Route index element={<ResetPassword />} />
      </Route>
      <Route path="/confirm-email" element={<RouteManager isPublic />}>
        <Route index element={<ConfirmEmail />} />
      </Route>

      <Route path="/account" element={<RouteManager />}>
        <Route index element={<UserAccountPage />} />
      </Route>

      <Route path="/tutorials" element={<RouteManager isPublic />}>
        <Route index element={<TutorialsPage />} />
      </Route>

      <Route path="/projects" element={<RouteManager />}>
        <Route index element={<ProjectsPage />} />
        <Route path=":projectId" element={<ProjectPage />} />
      </Route>

      <Route path="/inventory" element={<RouteManager />}>
        <Route path="" element={<InventoryPage />}>
          <Route index element={<InventoryClaysPage pageMainButton="createInventoryClay" />} />
          <Route
            path="clays/:inventoryClayId"
            element={<InventoryClayPage pageMainButton="createInventoryClayLot" />}
          />

          <Route
            path="custom-clays"
            element={<InventoryCustomClaysPage pageMainButton="createInventoryCustomClay" />}
          />
          <Route
            path="custom-clays/:inventoryCustomClayId"
            element={<InventoryCustomClayPage pageMainButton="deleteInventoryCustomClays" />}
          />
        </Route>
      </Route>

      <Route path="/color-mixer" element={<RouteManager />}>
        <Route index element={<ColorMixerPage />} />
      </Route>

      <Route path="/admin" element={<RouteManager admin />}>
        <Route path="" element={<AdminHomePage />}>
          <Route path="" element={<AdminClaysPage />} />
          <Route path="clays" element={<AdminClaysPage />} />
        </Route>
      </Route>
    </RoutesList>
  )
}
