import { useCallback, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { t } from 'i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { LockOutlined } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import { Button, Input } from 'components'
import { useToastContext } from 'contexts'
import { useAuth } from 'hooks'
import { ResetPasswordEndpoint } from 'routes'
import { validateToken } from './utils'

import { Container, Title, FormContainer, Form } from './styles'

const schema = Yup.object().shape({
  password: Yup.string().required().label(t('user:password'))
})

export const ResetPassword = () => {
  const [resetPassword, error, loading, status] = useAuth(ResetPasswordEndpoint, 'PUT')
  const { showToast } = useToastContext()
  const { search } = useLocation()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: { password: '' }
  })

  const onSubmit = useCallback(
    data => {
      if (!validateToken(search, data)) {
        showToast({
          type: 'error',
          title: t('reset-password:errors.default'),
          description: t('reset-password:errors.invalid-token')
        })

        return
      }

      resetPassword({ user: data })
    },
    [resetPassword, search, showToast]
  )

  useEffect(() => {
    if (status !== 204) return

    showToast({
      type: 'success',
      title: t('reset-password:success.default.title'),
      description: t('reset-password:success.default.description')
    })
  }, [status, showToast])

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: t('reset-password:errors.default'),
      description: error.message
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>{t('reset-password:title')}</Title>
          <Input
            type="password"
            name="password"
            label={t('user:password')}
            placeholder={t('user:password')}
            icon={LockOutlined}
            control={control}
            autoComplete="current-password"
            errorMessage={errors.password?.message as string}
            styles={{ marginTop: '0.8rem' }}
          />

          <Button
            type="submit"
            label={t('actions.submit')}
            styles={{ marginTop: '1rem' }}
            loading={loading}
          />
        </Form>
      </FormContainer>
    </Container>
  )
}
