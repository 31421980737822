import { Typography, TypographyProps } from '@mui/material'
import { PropsWithChildren } from 'react'

import { lineClampStyles } from './styles'

type Props = {
  lineClamp?: number
} & PropsWithChildren<TypographyProps>

export const TypographyEllipsis = ({ lineClamp, sx = {}, children, ...props }: Props) => {
  if (lineClamp) {
    lineClampStyles['-webkit-line-clamp'] = lineClamp.toString()
  }

  return (
    <Typography {...props} sx={{ ...sx, ...lineClampStyles }}>
      {children}
    </Typography>
  )
}
