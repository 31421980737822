import { useState, useRef, useCallback, useContext, useEffect } from 'react'
import { t } from 'i18next'
import { IconButton, Popover, MenuItem } from '@mui/material'
import { CloseOutlined, SendOutlined } from '@mui/icons-material/'
import { ThemeContext } from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Select, Input } from 'components'
import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { FeedbacksEndpoint } from 'routes/locations/feedbacks'

import {
  Container,
  FeedbackrIcon,
  Fieldset,
  Rating,
  SubmitButton,
  Form,
  CloseButton,
  popoverStyles,
  feedbackrIconButtonStyles
} from './styles'

export const schema = Yup.object().shape({
  rating: Yup.string().required(),
  kind: Yup.string().required(),
  name: Yup.string().required(),
  message: Yup.string().required(),
  email: Yup.string().required().email().label('Email')
})

export type FeedbackFormValues = Yup.InferType<typeof schema>

export const Feedbackr = () => {
  const trigger = useRef()
  const [open, setOpen] = useState(false)
  const theme = useContext(ThemeContext)
  const { showToast } = useToastContext()
  const [postFeedback, , postFeedbackError, , postFeedbackStatus] = useApi(FeedbacksEndpoint, 'POST')

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FeedbackFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      rating: null,
      kind: '',
      name: '',
      email: '',
      message: ''
    }
  })

  useEffect(() => {
    if (postFeedbackStatus !== 201) return

    showToast({
      type: 'success',
      title: t('feedbacks:success.title'),
      description: t('feedbacks:success.description')
    })
    setOpen(false)
    reset()
  }, [postFeedbackStatus, showToast, reset])

  useEffect(() => {
    if (!postFeedbackError) return

    showToast({
      type: 'error',
      title: t('feedbacks:errors.title'),
      description: t('feedbacks:errors.description')
    })
  }, [postFeedbackError, showToast, reset])

  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onSubmit = useCallback(
    data => {
      postFeedback({ ...data })
    },
    [postFeedback]
  )

  return (
    <Container>
      <IconButton ref={trigger} onClick={handleClick} size="large" sx={feedbackrIconButtonStyles}>
        <FeedbackrIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={trigger.current}
        onClose={handleClose}
        transformOrigin={{ vertical: 375, horizontal: 360 }}
        PaperProps={{
          style: {
            ...popoverStyles,
            color: theme.custom.base.text,
            background: theme.custom.card.bg
          }
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h3>{t('feedbacks:title')}</h3>

          <Controller
            name="rating"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Rating name="rating" value={parseInt(value, 10)} error={!!errors.rating} {...rest} />
            )}
          />

          <Select
            name="kind"
            label="Feedback"
            placeholder="Feedback"
            control={control}
            error={!!errors.kind}
            styles={{ width: '80%', height: 40 }}
          >
            <MenuItem value="suggestion">{t('feedbacks:suggestion')}</MenuItem>
            <MenuItem value="compliment">{t('feedbacks:compliment')}</MenuItem>
            <MenuItem value="critique">{t('feedbacks:critique')}</MenuItem>
          </Select>

          <Fieldset>
            <Input
              label={t('feedbacks:full-name')}
              placeholder={t('feedbacks:full-name')}
              name="name"
              control={control}
              error={!!errors.name}
            />

            <Input
              name="email"
              control={control}
              label={t('user:email')}
              placeholder={t('user:email')}
              error={!!errors.email}
            />
          </Fieldset>

          <Input
            name="message"
            label={t('feedbacks:message')}
            placeholder={t('feedbacks:message')}
            control={control}
            rows={4}
            styles={{ width: '80%' }}
            error={!!errors.message}
            multiline
          />

          <SubmitButton type="submit">
            <SendOutlined />
          </SubmitButton>

          <CloseButton size="small" onClick={handleClose}>
            <CloseOutlined />
          </CloseButton>
        </Form>
      </Popover>
    </Container>
  )
}
