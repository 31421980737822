import { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ToastMessage } from '.'

export const useToast = () => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const showToast = useCallback(({ type, title, description }: ToastMessage) => {
    const id = uuidv4()
    const toast: ToastMessage = {
      id,
      type,
      title,
      description
    }

    setMessages(prevMessages => [...prevMessages, toast])
  }, [])

  const hideToast = useCallback((id: Id) => {
    setMessages(state => state.filter(message => message.id !== id))
  }, [])

  return {
    messages,
    showToast,
    hideToast
  }
}
