import { AUTHORIZATION_KEY, CURRENT_USER_KEY } from 'hooks'

export const unauthenticate = () => {
  localStorage.removeItem(AUTHORIZATION_KEY)
  localStorage.removeItem(CURRENT_USER_KEY)

  if (!window.location.pathname.match(/sign-*/)) {
    window.location.replace('/sign-in')
  }
}
