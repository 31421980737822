import { createContext, useContext } from 'react'

import { useProjectController } from 'controllers'

export const ProjectContext = createContext<ReturnType<typeof useProjectController> | null>(null)

export const useProjectContext = () => {
  const context = useContext(ProjectContext)
  const errorMessage = 'useProjectContext must be used within a ProjectProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
