import { MutableRefObject } from 'react'
import ColorThief from 'colorthief'

import { rgbToHex } from 'utils'
import { ClayImageRef } from 'pages/admin/AdminClaysPage'

type ClayPayload = {
  id: Id
  hexColor: string
}

const colorThief = new ColorThief()

export const useSetClaysHexColor = (clayRefs: MutableRefObject<ClayImageRef[]>) => {
  const payload: ClayPayload[] = []

  const getUpdateClaysHexColorPayload = async () => {
    clayRefs.current.map(ref => {
      const hexColor = getHecColorFromImage(ref.image)
      payload.push({ id: ref.id, hexColor })
    })

    return payload
  }

  const getHecColorFromImage = (image: HTMLImageElement) => {
    const rgbColor = colorThief.getColor(image)
    return rgbToHex(rgbColor)
  }

  return {
    getHecColorFromImage,
    getUpdateClaysHexColorPayload
  }
}
