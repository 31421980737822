import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { Project } from 'models'
import { ProjectsEndpoint } from 'routes/locations'

export const useProjectsController = () => {
  const [fetchProjects, projects, , , , fetchProjectsMeta] = useApi<Project[]>(ProjectsEndpoint, 'GET')
  const [postProject, project, postProjectError, , postProjectStatus] = useApi<Project>(
    ProjectsEndpoint,
    'POST'
  )

  const { showToast } = useToastContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!postProjectError) return

    showToast({
      type: 'error',
      title: t('project:errors:create:title'),
      description: postProjectError.message
    })
  }, [postProjectError, showToast])

  useEffect(() => {
    if (!project || postProjectStatus !== 200) return

    navigate(project.id)
  }, [project, postProjectStatus, navigate])

  return {
    projects,
    fetchProjects,
    postProject,
    postProjectStatus,
    fetchProjectsMeta
  }
}
