import { createContext, useContext } from 'react'
import { useToast } from 'hooks'

export const ToastContext = createContext<ReturnType<typeof useToast> | null>(null)

export const useToastContext = () => {
  const context = useContext(ToastContext)
  const errorMessage = 'useToast must be used within a ToastProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
