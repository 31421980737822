import { Container } from './styles'
import { PageContainerProps } from './types'

export const PageContainer = ({
  children,
  styles,
  flex,
  flexColumn,
  hCenter,
  vCenter,
  center,
  gap,
  fullHeight,
  fullWidth
}: PageContainerProps) => {
  return (
    <Container
      $styles={styles}
      $flex={flex}
      $flexColumn={flexColumn}
      $hCenter={hCenter}
      $vCenter={vCenter}
      $center={center}
      $gap={gap}
      $fullHeight={fullHeight}
      $fullWidth={fullWidth}
    >
      {children}
    </Container>
  )
}
