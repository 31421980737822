import { useEffect } from 'react'
import { t } from 'i18next'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { InventoryClay } from 'models'
import { InventoryClaysEndpoint } from 'routes/locations'

export const useInventoryClaysController = () => {
  const { showToast } = useToastContext()

  const [fetchInventoryClays, inventoryClays, , , , fetchInventoryClaysMeta] = useApi<InventoryClay[]>(
    InventoryClaysEndpoint,
    'GET'
  )
  const [postInventoryClay, , postInventoryClayError, , postInventoryClayStatus] = useApi<InventoryClay>(
    InventoryClaysEndpoint,
    'POST'
  )

  useEffect(() => {
    if (postInventoryClayStatus !== 200) return

    showToast({
      type: 'success',
      title: t('inventory-clays:success:create:title'),
      description: t('inventory-clays:success:create:description')
    })
  }, [postInventoryClayStatus, showToast])

  useEffect(() => {
    if (!postInventoryClayError) return

    showToast({
      type: 'error',
      title: t('inventory-clays:errors:create:title'),
      description: postInventoryClayError.message
    })
  }, [postInventoryClayError, showToast])

  return {
    inventoryClays,
    fetchInventoryClays,
    postInventoryClay,
    postInventoryClayStatus,
    fetchInventoryClaysMeta
  }
}
