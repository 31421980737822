import { FC, PropsWithChildren as WithChildren } from 'react'
import { Box, BoxProps, IconProps, Typography, TypographyProps } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { boxStyles } from './styles'

type Props = {
  icon?: FC<IconProps>
  typographyProps?: TypographyProps
} & BoxProps

export const InfoBox = ({
  icon: Icon = InfoOutlined,
  typographyProps,
  children,
  sx,
  ...rest
}: WithChildren<Props>) => {
  return (
    <Box sx={{ ...boxStyles, ...sx }} {...rest}>
      <Icon />
      <Typography variant="body2" color="text.secondary" {...typographyProps}>
        {children}
      </Typography>
    </Box>
  )
}
