import { forwardRef, Ref } from 'react'
import { Controller } from 'react-hook-form'
import { InputAdornment } from '@mui/material'
import { Container, Error, StyledTextField, AlertIcon } from './styles'
import { InputProps, InputRef } from './types'

export const InputComponent = (
  {
    label,
    name,
    value = '',
    errorMessage,
    placeholder,
    type = 'text',
    control,
    styles,
    className,
    icon: Icon,
    ...rest
  }: InputProps,
  ref: Ref<InputRef>
) => {
  return (
    <Container styles={styles} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledTextField
            ref={ref}
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            type={type}
            error={Boolean(errorMessage)}
            {...rest}
            {...field}
            InputProps={
              Icon && {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                )
              }
            }
          />
        )}
      />
      {errorMessage && (
        <Error title={errorMessage} arrow>
          <AlertIcon color="error" />
        </Error>
      )}
    </Container>
  )
}

export const Input = forwardRef<InputRef, InputProps>(InputComponent)
