import { useEffect } from 'react'
import { t } from 'i18next'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { InventoryClayLot } from 'models'
import { InventoryClayLotsEndpoint } from 'routes/locations'

export const useInventoryClayLotsController = (inventoryClayId?: Id) => {
  const [createInventoryClayLot, , createInventoryClayLotError, , createInventoryClayLotStatus] =
    useApi<InventoryClayLot>(InventoryClayLotsEndpoint, 'POST', { inventoryClayId })

  const { showToast } = useToastContext()

  useEffect(() => {
    if (createInventoryClayLotStatus !== 200) return

    showToast({
      type: 'success',
      title: t('inventory-clay-lots:success:create:title'),
      description: t('inventory-clay-lots:success:create:description')
    })
  }, [createInventoryClayLotStatus, showToast])

  useEffect(() => {
    if (!createInventoryClayLotError) return

    showToast({
      type: 'error',
      title: t('inventory-clay-lots:errors:create:title'),
      description: createInventoryClayLotError.message
    })
  }, [createInventoryClayLotError, showToast])

  return {
    createInventoryClayLot,
    createInventoryClayLotStatus
  }
}
