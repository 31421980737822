import { lighten, transparentize } from 'polished'

import { colors } from 'styles'

const monochrome = {
  accent: colors.accent,
  accentShadow: transparentize(0.3, lighten(0.5, colors.accent)),

  button: {
    text: colors.offWhite,
    bg: colors.accent,
    disabled: colors.gray_300,
    disabledText: colors.shadow_250,
    disabledOpacity: 0.7
  },

  levels: {
    easy: colors.green,
    medium: colors.yellow,
    hard: colors.red
  }
}

export const lightTheme = {
  custom: {
    name: 'light',

    base: {
      text: colors.offBlack,
      bg: colors.gray_100
    },
    invertedBase: {
      text: colors.gray_100,
      bg: colors.offBlack
    },
    buttonIcon: {
      hover: {
        text: colors.offBlack
      }
    },
    buttonIconMoon: {
      hover: {
        text: colors.offBlack,
        bg: colors.transparent
      }
    },
    card: {
      text: colors.offBlack,
      bg: colors.offYellow,
      wave: colors.accentShadow,
      shadow: colors.shadow_200
    },
    input: {
      bg: colors.gray_100
    },
    topbar: {
      text: colors.offWhite,
      bg: colors.accent,

      hover: {
        text: colors.offBlack
      }
    },
    separtor: {
      bg: colors.gray_100
    },

    ...monochrome
  }
}

export const darkTheme = {
  name: 'dark',

  base: {
    text: colors.offWhite,
    bg: colors.offBlack
  },
  buttonIcon: {
    hover: {
      text: colors.accent
    }
  },
  buttonIconMoon: {
    hover: {
      text: colors.accent,
      bg: colors.accent
    }
  },
  card: {
    text: colors.offWhite,
    bg: colors.gray_800,
    wave: colors.shadow_200,
    shadow: colors.shadow_700
  },
  input: {
    bg: colors.gray_700
  },
  topbar: {
    text: colors.offWhite,
    bg: colors.offBlack,

    hover: {
      text: colors.accent
    }
  },
  separtor: {
    bg: colors.gray_700
  },

  ...monochrome
}
