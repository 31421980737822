import { ReactNode, useEffect } from 'react'

import { useClayFieldsContext, useCurrentUserContext } from 'contexts'
import { useAuthToken, useDocumentTitle } from 'hooks'
import { ClayFieldsProvider, CurrentUserProvider, ToastProvider } from 'providers'

type Props = {
  children: ReactNode
}

export const GlobalProviderFC = ({ children }: Props) => {
  useDocumentTitle()

  const { fetchBrands, fetchInventoryClays } = useClayFieldsContext()
  const { token } = useAuthToken()
  const { currentUser } = useCurrentUserContext()

  useEffect(() => {
    if (!token || !currentUser) return
    fetchBrands()
    fetchInventoryClays()
  }, [currentUser, token]) // eslint-disable-line

  return <>{children}</>
}

export const GlobalProvider = ({ children }: Props) => {
  return (
    <CurrentUserProvider>
      <ToastProvider>
        <ClayFieldsProvider>
          <GlobalProviderFC>{children}</GlobalProviderFC>
        </ClayFieldsProvider>
      </ToastProvider>
    </CurrentUserProvider>
  )
}
