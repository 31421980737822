import { ReactNode } from 'react'

import { InventoryCustomClaysContext } from 'contexts'
import { useInventoryCustomClaysController } from 'controllers'

type Props = {
  children: ReactNode
}

export const InventoryCustomClaysProvider = ({ children }: Props) => {
  return (
    <InventoryCustomClaysContext.Provider value={useInventoryCustomClaysController()}>
      {children}
    </InventoryCustomClaysContext.Provider>
  )
}
