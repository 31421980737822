import { Box, Typography } from '@mui/material'

import { ConfirmDialog, ConfirmDialogProps, InfoBox } from 'components'

type Props = {
  claysCount: number
} & Omit<ConfirmDialogProps, 'title' | 'content'>

export const SetClaysHexColorDialog = ({ claysCount, ...rest }: Props) => {
  return (
    <ConfirmDialog
      submitLabel="Set Hex Color"
      title="Set Clays Hex Color"
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {claysCount && <InfoBox>This will affect {claysCount} clays</InfoBox>}
          <Typography>Are you are you want to set clays hex color?</Typography>
        </Box>
      }
      {...rest}
    />
  )
}
