import { t } from 'i18next'
import { Box, Typography } from '@mui/material'

import { ConfirmDialog, ConfirmDialogProps, InfoBox } from 'components'

type Props = {
  projectInventoryClayLotCount: number
} & Omit<ConfirmDialogProps, 'title' | 'content'>

export const DeleteInventoryCustomClayDialog = ({ projectInventoryClayLotCount, ...rest }: Props) => {
  return (
    <ConfirmDialog
      submitLabel="Delete"
      title={t('inventory-custom-clay:delete.title')}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {!!projectInventoryClayLotCount && (
            <InfoBox>
              {t('inventory-custom-clay:delete.alert', { count: projectInventoryClayLotCount })}
            </InfoBox>
          )}
          <Typography>{t('inventory-custom-clay:delete.confirm')}</Typography>
        </Box>
      }
      {...rest}
    />
  )
}
