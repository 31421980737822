import { Typography } from '@mui/material'
import { CSSObject } from 'styled-components'
import { t } from 'i18next'

import { Container } from './styles'
import EmptyStateSvg from 'assets/images/empty-box.svg'

type Props = {
  message: string
  styles?: CSSObject
}

export const EmptyState = ({ message, styles }: Props) => {
  const text = message || t('empty-state')

  return (
    <Container $styles={styles}>
      <img src={EmptyStateSvg} alt="Empty inventory" />
      <Typography variant="h6" color="text.secondary">
        {text}
      </Typography>
    </Container>
  )
}
