import { User } from 'models'

import { AUTHORIZATION_KEY, CURRENT_USER_KEY } from '.'

import { isEmpty } from 'utils'

type UserFromAuthToken = Pick<User, 'id' | 'email'>

export const useAuthToken = () => {
  const token = localStorage.getItem(AUTHORIZATION_KEY)
  const loggedInUser = JSON.parse(localStorage.getItem(CURRENT_USER_KEY))

  if (!token || isEmpty(loggedInUser)) return {}

  const currentUser: UserFromAuthToken = JSON.parse(window.atob(token.split('.')[1]))

  return { token, currentUser }
}
