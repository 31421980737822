import { ReactNode } from 'react'
import { Toast } from 'components'
import { useToast } from 'hooks'
import { ToastContext } from 'contexts'

type Props = {
  children: ReactNode
}

export const ToastProvider = ({ children }: Props) => {
  const toast = useToast()

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <Toast messages={toast.messages} />
    </ToastContext.Provider>
  )
}
