import { Typography, Card, CardMedia, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

import { TypographyEllipsis } from 'components'
import { InventoryClayLot } from 'models'
import { CSSObject } from 'styled-components'

type Props = {
  inventoryClayLot: InventoryClayLot
  lotCode?: string
  weight?: number
  description?: string
  portrait?: boolean
  width?: string
  height?: string
  mediaStyles?: CSSObject
}

export const InventoryClayLotCard = ({
  inventoryClayLot,
  lotCode = inventoryClayLot.code,
  weight = inventoryClayLot.weight,
  width = 'auto',
  height = 'auto',
  mediaStyles = {},
  description,
  portrait
}: Props) => {
  const cardStyles = {
    display: 'flex',
    width: `calc(${width} - 16px)`,
    height
  }
  const { inventoryClay } = inventoryClayLot
  const portraitStyles = { flexDirection: 'column' }
  const cardMediaStyles = {
    flex: 1,
    objectFit: 'cover',
    py: portrait ? 'unset' : 2,
    ...mediaStyles
  }

  return (
    <Card
      component={Link}
      to={`/inventory/clays/${inventoryClay.id}`}
      sx={{ ...cardStyles, ...(portrait && portraitStyles) }}
    >
      <CardMedia
        component="img"
        image={inventoryClay.clay.photoUrl}
        alt={inventoryClay.clay.color}
        sx={cardMediaStyles}
      />
      <CardContent sx={{ flex: 1.5 }}>
        <Typography variant="subtitle1">{inventoryClay.clay.color}</Typography>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {inventoryClay.clay.brand.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t('inventory-clay-lot:resource-short')}:</strong> #{lotCode}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t('inventory-clay-lot:weight')}:</strong> {weight}g
        </Typography>
        {description && (
          <TypographyEllipsis variant="body2" color="text.secondary" lineClamp={2} sx={{ mt: 1 }}>
            {description}
          </TypographyEllipsis>
        )}
      </CardContent>
    </Card>
  )
}
