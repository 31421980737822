import { useClayFieldsContext } from 'contexts'
import { ClayGroup, ClayOption, ClayOptions, InventoryClayOption } from '.'

export const useClayOptionsLookup = (inventoryOnly?: boolean) => {
  const { brands, inventoryClays } = useClayFieldsContext()

  const getClaysByGroup = (brandId: Id) => {
    if (!brandId) return
    return getSelectedBrand(brandId)?.clays.reduce(
      (acc, clay) => {
        const isClayInInventory = !!inventoryClays.find(inventoryClay => inventoryClay.clay.id === clay.id)
        const group: ClayGroup['group'] = isClayInInventory ? 'inventory' : 'other'
        acc[group].push({ ...clay, group })
        return acc
      },
      { inventory: [], other: [] } as ClayOptions
    )
  }

  const getAllClayOptions = (brandId: Id): ClayOption[] => {
    if (!brandId) return []
    return Object.values(getClaysByGroup(brandId)).flat()
  }

  const getInventoryClayOptions = (brandId: Id): InventoryClayOption[] => {
    if (!brandId) return []
    return inventoryClays
      .filter(inventoryClay => inventoryClay.clay.brandId === brandId)
      .map(inventoryClay => ({
        id: inventoryClay.id,
        color: inventoryClay.clay.color,
        ref: inventoryClay.clay.ref
      }))
  }

  const getSelectedBrand = (brandId: Id) => {
    return brands.find(brand => brand.id === brandId)
  }

  const getClayOptions = inventoryOnly ? getInventoryClayOptions : getAllClayOptions

  return {
    getClayOptions
  }
}
