import {
  InventoryClay,
  InventoryCustomClay,
  InventoryCustomClayWeight,
  ProjectInventoriableClay
} from 'models'

export class InventoryClayLot {
  id: Id
  code: string
  weight: number
  usedWeight: number

  inventoryClay: InventoryClay
  inventoryCustomClay: InventoryCustomClay
  projectInventoriableClays: ProjectInventoriableClay[]
  inventoryCustomClayWeights: InventoryCustomClayWeight[]

  constructor(inventoryClayLotParams) {
    const {
      inventoryCustomClay: inventoryCustomClayParams,
      projectInventoriableClays: projectInventoriableClaysParams,
      inventoryCustomClayWeights: inventoryCustomClayWeightsParams
    } = inventoryClayLotParams

    const inventoryCustomClay = inventoryCustomClayParams?.map(params => new InventoryClayLot(params)) || []
    const projectInventoriableClays =
      projectInventoriableClaysParams?.map(params => new ProjectInventoriableClay(params)) || []
    const inventoryCustomClayWeights =
      inventoryCustomClayWeightsParams?.map(params => new InventoryClayLot(params)) || []

    Object.assign(this, {
      ...inventoryClayLotParams,
      inventoryCustomClay,
      projectInventoriableClays,
      inventoryCustomClayWeights
    })
  }

  get availableWeight() {
    return this.weight - this.usedWeight
  }
}
