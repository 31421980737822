import { forwardRef, Ref } from 'react'
import { Box, Card, CardMedia, CardContent, Typography } from '@mui/material'
import { DangerousOutlined } from '@mui/icons-material'

import { TypographyEllipsis } from 'components'
import { Clay } from 'models'
import { cardContentStyles, cardStyles, ColorSwatch } from './styles'

type Props = {
  clay: Clay
}

export const AdminClayCardFC = ({ clay }: Props, ref: Ref<any>) => {
  return (
    <Card sx={cardStyles} key={clay.id}>
      <CardMedia
        component="img"
        image={clay.photoUrl}
        alt={clay.color}
        sx={{ height: 140 }}
        ref={ref}
        crossOrigin="anonymous"
      />
      <CardContent sx={cardContentStyles}>
        <TypographyEllipsis lineClamp={2} variant="h5" gutterBottom>
          {clay.color}
        </TypographyEllipsis>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color={clay.hexColor ? 'text.secondary' : 'error'}>
            <strong>Hex:</strong> {clay.hexColor}
          </Typography>
          {clay.hexColor ? <ColorSwatch hexColor={clay.hexColor} /> : <DangerousOutlined color="error" />}
        </Box>
      </CardContent>
    </Card>
  )
}

export const AdminClayCard = forwardRef(AdminClayCardFC)
