import { Routes } from 'routes'
import { BrowserRouter } from 'react-router-dom'

import { Topbar, Feedbackr } from 'components'
import { GlobalProvider } from 'providers'
import { GlobalStyles, lightTheme } from 'styles'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import 'i18n'
import 'config/sentry'

const appTheme = createTheme({
  palette: {
    primary: { main: '#c51162', light: '#fd558f', dark: '#8e0038' },
    secondary: { main: '#ff80ab', light: '#ffb2dd', dark: '#c94f7c' }
  },
  ...lightTheme
})

const App = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>
        <GlobalProvider>
          <Topbar />
          <Routes />
          <Feedbackr />
        </GlobalProvider>
      </BrowserRouter>

      <GlobalStyles />
    </ThemeProvider>
  )
}

export default App
