import { useState } from 'react'
import { t } from 'i18next'
import { Button } from '@mui/material'

import { useInventoryClaysContext } from 'contexts'
import { CreateInventoryClayFormDialog } from 'pages/inventory/components'

export const CreateInventoryClayButtonForm = () => {
  const { fetchInventoryClays } = useInventoryClaysContext()
  const [isFormDialogOpen, setIsFormDialog] = useState<boolean>(false)

  const handleButtonClick = () => {
    setIsFormDialog(true)
  }

  const handleClose = () => {
    setIsFormDialog(false)
  }

  return (
    <>
      <Button onClick={handleButtonClick} sx={{ alignSelf: 'flex-end' }}>
        {t('inventory-clays:create')}
      </Button>

      <CreateInventoryClayFormDialog
        open={isFormDialogOpen}
        onClose={handleClose}
        onSuccess={fetchInventoryClays}
      />
    </>
  )
}
