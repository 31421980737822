import { forwardRef, Ref } from 'react'

import { Autocomplete, AutocompleteProps, AutocompleteRef } from '../Autocomplete'
import { filterOptions, getOptionLabel } from './utils'

type Props = {
  optionLabel?: string
} & AutocompleteProps

const AutocompleteWithAddOptionFC = (
  { optionLabel = 'label', ...rest }: Props,
  ref: Ref<AutocompleteRef>
) => {
  return (
    <Autocomplete
      // @ts-ignore
      ref={ref}
      renderOption={(optionProps, option) => <li {...optionProps}>{option[optionLabel]}</li>}
      getOptionLabel={option => getOptionLabel(option, optionLabel)}
      filterOptions={(optionList, params) => filterOptions(optionList, params, optionLabel)}
      freeSolo
      {...rest}
    />
  )
}

export const AutocompleteWithAddOption = forwardRef<AutocompleteRef, Props>(AutocompleteWithAddOptionFC)
