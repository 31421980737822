import { CSSObject } from 'styled-components'
import { StyledLoadingButton } from './styles'

type Props = {
  label: string
  loading?: boolean
  styles?: CSSObject
  type?: 'button' | 'submit' | 'reset'
}

export const Button = ({ label, loading, styles, type = 'button' }: Props) => {
  return (
    <StyledLoadingButton type={type} variant="contained" styles={styles} loading={loading}>
      {label}
    </StyledLoadingButton>
  )
}
