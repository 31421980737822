import { useEffect } from 'react'
import { t } from 'i18next'
import { Box, Typography } from '@mui/material'
import { useOutletContext } from 'react-router-dom'

import { PageContainer } from 'components'
import { useInventoryCustomClayContext } from 'contexts'
import { InventoryClayLotCard } from 'pages/inventory/components'
import { formatWeight } from 'utils'

import { PageMainButtonProps, PageMainButtonOutletContextProps } from '../types'
import { ColorSwatch } from './styles'

export const InventoryCustomClayPage = ({ pageMainButton }: PageMainButtonProps) => {
  const { fetchInventoryCustomClay, inventoryCustomClay } = useInventoryCustomClayContext()

  const { setPageMainButton } = useOutletContext<PageMainButtonOutletContextProps>()

  useEffect(() => {
    fetchInventoryCustomClay()
  }, []) // eslint-disable-line

  useEffect(() => {
    setPageMainButton(pageMainButton)
  }, []) // eslint-disable-line

  if (!inventoryCustomClay) {
    return <>{t('loading')}</>
  }

  return (
    <PageContainer flexColumn fullWidth gap="36px">
      <Box display="flex" flexDirection="column" alignItems="center">
        <ColorSwatch hex={inventoryCustomClay.hexColor} />
        <Typography variant="h5" gutterBottom sx={{ mt: 5 }}>
          {inventoryCustomClay.name}
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          {t('inventory-custom-clay:weight')}: {formatWeight(inventoryCustomClay.weight)}
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          {t('inventory-custom-clay:available-weight')}: {formatWeight(inventoryCustomClay.availableWeight)}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" gap={2}>
        {inventoryCustomClay.inventoryClayLots.map(inventoryClayLot => (
          <InventoryClayLotCard
            key={inventoryClayLot.id}
            inventoryClayLot={inventoryClayLot}
            weight={inventoryClayLot.inventoryCustomClayWeights[0].weight}
            width="20%"
            portrait
          />
        ))}
      </Box>
    </PageContainer>
  )
}
