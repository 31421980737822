import { useState, MouseEvent } from 'react'
import { Box, IconButton, Menu as MaterialMenu, MenuItem, ListItemIcon } from '@mui/material'
import { MoreVertOutlined } from '@mui/icons-material'

import { MenuItemProps } from 'components'
import { MenuProps } from './types'

export const Menu = ({ items, ...rest }: MenuProps) => {
  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const handleMenuItemClick = (item: MenuItemProps) => {
    item.onClick()
    setMenuAnchor(null)
  }

  return (
    <Box {...rest}>
      <IconButton onClick={handleMenu}>
        <MoreVertOutlined />
      </IconButton>
      <MaterialMenu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleMenuClose}>
        {items.map(item => {
          const { icon: MenuItemIcon } = item
          return (
            <MenuItem key={item.id} onClick={() => handleMenuItemClick(item)}>
              <ListItemIcon>
                <MenuItemIcon fontSize="small" />
              </ListItemIcon>
              {item.label}
            </MenuItem>
          )
        })}
      </MaterialMenu>
    </Box>
  )
}
