import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { humanize } from 'utils'

const APP_TITLE = 'Central Clay'

export const useDocumentTitle = () => {
  const { pathname } = useLocation()
  const routeName = pathname.split('/')?.at(1)

  useEffect(() => {
    const routeTitle = `${routeName ? '-' : ''} ${humanize(routeName)}`
    document.title = `${APP_TITLE} ${routeTitle}`
  }, [routeName])
}
