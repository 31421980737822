import { useCallback, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { t } from 'i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EmailOutlined, LockOutlined, PersonOutlined } from '@mui/icons-material'

import { Button, Input } from 'components'
import { useToastContext } from 'contexts'
import { useAuth } from 'hooks'
import { UsersEndpoint } from 'routes'

import { Container, Title, FormContainer, Form, Link, Footer, InputGroup } from './styles'

const schema = Yup.object().shape({
  first_name: Yup.string().required().label(t('user:first-name')),
  last_name: Yup.string().required().label(t('user:last-name')),
  email: Yup.string().required().email().label(t('user:email')),
  password: Yup.string().required().label(t('user:password'))
})

export const SignUp = () => {
  const [signUp, error, loading] = useAuth(UsersEndpoint, 'POST')
  const { showToast } = useToastContext()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: ''
    }
  })

  const onSubmit = useCallback(
    data => {
      signUp({ user: data })
    },
    [signUp]
  )

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: t('sign-up:errors.default'),
      description: error.message
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>{t('sign-up:title')}</Title>

          <InputGroup>
            <Input
              type="text"
              name="first_name"
              label={t('user:first-name')}
              placeholder={t('user:first-name')}
              control={control}
              icon={PersonOutlined}
              errorMessage={errors.first_name?.message as string}
            />
            <Input
              type="text"
              name="last_name"
              label={t('user:last-name')}
              placeholder={t('user:last-name')}
              control={control}
              icon={PersonOutlined}
              errorMessage={errors.last_name?.message as string}
            />
          </InputGroup>

          <Input
            type="email"
            name="email"
            label={t('user:email')}
            placeholder={t('user:email')}
            icon={EmailOutlined}
            control={control}
            autoComplete="username"
            errorMessage={errors.email?.message as string}
            styles={{ marginTop: '0.8rem' }}
          />

          <Input
            type="password"
            name="password"
            label={t('user:password')}
            placeholder={t('user:password')}
            icon={LockOutlined}
            control={control}
            autoComplete="current-password"
            errorMessage={errors.password?.message as string}
            styles={{ marginTop: '0.8rem' }}
          />

          <Button
            type="submit"
            label={t('actions.submit')}
            styles={{ marginTop: '1rem' }}
            loading={loading}
          />

          <Footer>
            <Link to="/sign-in">{t('sign-in:sign-in-account')}</Link>
          </Footer>
        </Form>
      </FormContainer>
    </Container>
  )
}
