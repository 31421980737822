import styled, { CSSObject } from 'styled-components'

type ContainerProps = {
  $styles: CSSObject
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;

  ${props => props.$styles}
`
