import { MenuItemProps } from 'components'

import { inventoryClayCardMenuItems } from './constants'

export const getInventoryClayLotCardMenuProps = (props?: MenuItemProps[]) => {
  if (!props) return inventoryClayCardMenuItems()

  return inventoryClayCardMenuItems().map(menuItem => {
    const prop = props.find(({ id }) => id === menuItem.id)
    return { ...menuItem, ...prop }
  })
}
