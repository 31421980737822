import Chroma from 'chroma-js'

import { ClayFormValues } from '../../types'

export const getMixedColor = (clayList: ClayFormValues[]) => {
  if (!clayList) return

  const hexColorList = clayList?.map(clay => clay.hexColor).filter(Boolean)
  const weightList = clayList?.map(clay => parseFloat(clay.weight?.toString())).filter(Boolean)

  const isPresent = hexColorList?.length && weightList?.length
  const isSameLength = hexColorList?.length === weightList?.length

  return isPresent && isSameLength && Chroma.average(hexColorList, 'lrgb', weightList).hex()
}
