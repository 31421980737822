import { ProjectPhoto, ProjectInventoriableClay, User } from 'models'

type ProjectSettings = {
  height?: number
  width?: number
  weight?: number
}

export class Project {
  id: Id
  title: string
  description?: string
  settings: ProjectSettings
  createdAt: string
  updatedAt: string

  user: User
  projectPhotos?: ProjectPhoto[]
  projectInventoriableClays?: ProjectInventoriableClay[]

  constructor(projectParams) {
    const { projectInventoriableClays: projectInventoriableClaysParams } = projectParams
    const projectInventoriableClays =
      projectInventoriableClaysParams?.map(params => new ProjectInventoriableClay(params)) || []
    Object.assign(this, {
      ...projectParams,
      projectInventoriableClays
    })
  }
}
