import { createContext, useContext } from 'react'

import { useInventoryCustomClaysController } from 'controllers'

export const InventoryCustomClaysContext = createContext<ReturnType<
  typeof useInventoryCustomClaysController
> | null>(null)

export const useInventoryCustomClaysContext = () => {
  const context = useContext(InventoryCustomClaysContext)
  const errorMessage = 'useInventoryCustomClaysContext must be used within a InventoryCustomClaysProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
