import { css, CSSObject } from 'styled-components'
import { variables } from '.'

export const buttonStyles = css`
  height: 48px;
  padding: 0 20px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.custom.button.text};
  letter-spacing: 0.2px;
  opacity: 1;
  border-radius: 5px;
  transition: background 0.1s;
  cursor: pointer;
  background: ${props => props.theme.custom.accent};

  &:hover {
  }
`
export const outlineButtonStyles = css`
  ${buttonStyles}
  color: ${props => props.theme.custom.accent};
  border: 1px solid ${props => props.theme.custom.accent};
  background: transparent;
`
export const inputStyles = css`
  height: 48px;
  border-radius: 5px;
  margin: 5px 0;
  border: none;
  color: ${props => props.theme.custom.base.text};
  padding: 0 20px;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: Montserrat, sans-serif;
  font-weight: 200px;
  background: ${props => props.theme.custom.input.bg};

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 100px;
  }
`
export const outlineInputStyles = css`
  ${inputStyles}
  border: 1px solid #333336;
  background: transparent;
`
export const menuStyles = css`
  .MuiList-root {
    min-width: 120px;
    background: ${props => props.theme.custom.card.bg};
  }

  .MuiListItem-root {
    color: ${({ theme }) => theme.custom.base.text};

    &:hover * {
      color: ${({ theme }) => theme.custom.accent};
    }
  }

  .MuiListItemIcon-root {
    min-width: 36px;
    color: inherit;
  }
`
export const containerPageStyles = css`
  width: 80%;

  @media (max-width: 960px) {
    width: 90%;
  }
`
export const pageEmptyStateStyles = {
  position: 'absolute' as CSSObject['position'],
  top: 0,
  left: 0,
  width: '100%',
  height: `calc(100vh - ${variables.topbarHeight + variables.createResourceButtonHeight}px)`
}
