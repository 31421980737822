import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { t } from 'i18next'

import { FormDialog, FormDialogProps } from 'components'
import { Project } from 'models'
import { serializeAll } from 'utils'

import { ProjectFormFields } from '..'
import { projectSchema, ProjectFormValues } from './schema'
import { getProjectInitialValues, markClaysToDestroy, getProjectInventoriableClaysPayload } from './utils'

type Props = {
  submitAction: (data: any) => Promise<any>
  project?: Project
} & Omit<FormDialogProps, 'title' | 'content' | 'onSubmit'>

const PROJECT_INITIAL_VALUES = {
  title: '',
  description: '',
  settings: {
    height: null,
    width: null,
    weight: null
  }
}

export const ProjectFormDialog = ({ project, submitAction, submitLabel, ...rest }: Props) => {
  const form = useForm<ProjectFormValues>({
    resolver: yupResolver(projectSchema),
    defaultValues: project ? getProjectInitialValues(project) : PROJECT_INITIAL_VALUES
  })

  const { handleSubmit } = form

  const onSubmit = useCallback(
    async data => {
      const { clays: formClays, ...params } = data
      const inventoriableClaysData = getProjectInventoriableClaysPayload(formClays)
      const inventoriableClaysPayload = markClaysToDestroy(
        project?.projectInventoriableClays,
        inventoriableClaysData
      )

      return submitAction({
        project: {
          ...params,
          project_inventoriable_clays_attributes: serializeAll(inventoriableClaysPayload)
        }
      })
    },
    [project?.projectInventoriableClays, submitAction]
  )

  return (
    <FormDialog
      title={`${submitLabel} ${t('project:resource')}`}
      onSubmit={handleSubmit(onSubmit)}
      submitLabel={submitLabel}
      content={<ProjectFormFields form={form} project={project} />}
      maxWidth="sm"
      {...rest}
    />
  )
}
