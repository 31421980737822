import { useEffect } from 'react'
import { t } from 'i18next'
import { Button, Dialog as MaterialDialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

import { FormDialogProps } from './types'

export const FormDialog = ({
  open,
  onClose,
  onSuccess,
  onSubmit,
  submitLabel = t('actions.submit'),
  title,
  content,
  contentProps,
  requestStatus,
  ...rest
}: FormDialogProps) => {
  useEffect(() => {
    if (!requestStatus) return

    const isSuccessfulRequest = requestStatus === 200 || requestStatus === 201

    if (isSuccessfulRequest) {
      onSuccess?.()
      onClose()
    }
  }, [requestStatus]) //eslint-disable-line

  return (
    <MaterialDialog open={open} onClose={onClose} fullWidth {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent {...contentProps}>{content}</DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('actions.cancel')}</Button>
          <Button type="submit">{submitLabel}</Button>
        </DialogActions>
      </form>
    </MaterialDialog>
  )
}
