import { UseFormReturn } from 'react-hook-form'
import { t } from 'i18next'

import { Input } from 'components'
import { Project } from 'models'
import { ProjectFormValues } from '../ProjectFormDialog/schema'

import { ProjectInventoriableClayFields } from '..'

type Props = {
  form: UseFormReturn<ProjectFormValues>
  project?: Project
}

export const ProjectFormFields = ({ form, project }: Props) => {
  const {
    control,
    formState: { errors }
  } = form

  return (
    <>
      <Input
        type="text"
        name="title"
        label={t('project:title')}
        placeholder="Title"
        control={control}
        errorMessage={errors.title?.message}
        variant="standard"
      />
      <Input
        type="number"
        name="settings.height"
        label={`${t('project:settings.height')} (cm)`}
        placeholder="Height (cm)"
        control={control}
        errorMessage={errors.settings?.height?.message}
        variant="standard"
      />
      <Input
        type="number"
        name="settings.width"
        label={`${t('project:settings.width')} (cm)`}
        placeholder="Width (cm)"
        control={control}
        errorMessage={errors.settings?.width?.message}
        variant="standard"
      />
      <Input
        type="number"
        name="settings.weight"
        label={`${t('project:settings.weight')} (g)`}
        placeholder="Weight (g)"
        control={control}
        errorMessage={errors.settings?.weight?.message}
        variant="standard"
      />
      <Input
        name="description"
        label={`${t('project:notes')}`}
        placeholder="Notes"
        control={control}
        variant="standard"
        styles={{ marginTop: '0.8rem', marginBottom: '1.6rem' }}
        rows={4}
        multiline
      />

      <ProjectInventoriableClayFields form={form} project={project} />
    </>
  )
}
