import { forwardRef, Ref } from 'react'
import { Select as MaterialSelect, FormControl, InputLabel, MenuItem } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Container, Error, AlertIcon } from './styles'
import { SelectProps, SelectRef } from './types'

const SelectComponent = (
  {
    label,
    name,
    value = '',
    errorMessage,
    placeholder,
    control,
    styles,
    children,
    emptyMessage,
    ...rest
  }: SelectProps,
  ref: Ref<SelectRef>
) => {
  return (
    <Container styles={styles}>
      <FormControl fullWidth>
        <InputLabel id="material-select" error={Boolean(errorMessage)}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <MaterialSelect
              labelId="material-select"
              label={label}
              name={name}
              value={value}
              placeholder={placeholder}
              error={Boolean(errorMessage)}
              {...field}
              {...rest}
            >
              {children?.length ? (
                children
              ) : (
                <MenuItem value="" sx={{ color: 'gray', fontSize: 12 }} ref={ref}>
                  {emptyMessage}
                </MenuItem>
              )}
            </MaterialSelect>
          )}
        />
        {errorMessage && (
          <Error title={errorMessage} arrow>
            <AlertIcon color="error" />
          </Error>
        )}
      </FormControl>
    </Container>
  )
}

export const Select = forwardRef<SelectRef, SelectProps>(SelectComponent)
