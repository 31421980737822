import styled from 'styled-components'

export const InputGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;

  > div {
    flex: 1;
  }
`
