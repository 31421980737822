import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { t } from 'i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { AutocompleteWithAddOption, Input, FormDialog, FormDialogProps } from 'components'
import { useInventoryClayLotController, useInventoryClayLotsController } from 'controllers'
import { useClayFieldsContext, useInventoryClaysContext } from 'contexts'
import { useFinder } from 'hooks'
import { formatWeight, serialize, isEmpty, numberTextFieldYupPatch } from 'utils'

import { InputGroup } from './styles'
import { InventoryClayFormFields } from '../../InventoryClaysPage/components'

const INITIAL_INVENTORY_CLAY_VALUES: InventoryClayFormValues = {
  brandId: '',
  clayId: '',
  inventoryClayLotsAttributes: [{ id: '', code: '', weight: undefined }]
}

const inventoryClaySchema = () =>
  Yup.object().shape({
    brandId: Yup.string().required().label(t('brand:resource')),
    clayId: Yup.string().required().label(t('clay:color')),
    inventoryClayLotsAttributes: Yup.array().of(
      Yup.object({
        id: Yup.string(),
        code: Yup.string().required().label(t('inventory-clay-lot:code')),
        weight: Yup.number()
          .min(1)
          .required()
          .transform(numberTextFieldYupPatch)
          .label(t('inventory-clay-lot:weight'))
      })
    )
  })

export type InventoryClayFormValues = Yup.InferType<ReturnType<typeof inventoryClaySchema>>

type Props = {
  initialValues?: InventoryClayFormValues
} & Omit<FormDialogProps, 'title' | 'content' | 'onSubmit' | 'requestStatus'>

export const CreateInventoryClayFormDialog = ({ onClose, onSuccess, initialValues = {}, ...rest }: Props) => {
  const { postInventoryClay, postInventoryClayStatus } = useInventoryClaysContext()
  const { updateInventoryClayLot, updateInventoryClayLotStatus } = useInventoryClayLotController()
  const { createInventoryClayLot, createInventoryClayLotStatus } = useInventoryClayLotsController()
  const {
    brands,
    inventoryClays,
    fetchInventoryClays: fetchClayFieldsInventoryClays
  } = useClayFieldsContext()
  const { findRecord: findBrand } = useFinder(brands)
  const { findRecordBy: findInventoryClayBy } = useFinder(inventoryClays)

  const form = useForm<InventoryClayFormValues>({
    resolver: yupResolver(inventoryClaySchema()),
    defaultValues: {
      ...INITIAL_INVENTORY_CLAY_VALUES,
      ...initialValues
    }
  })

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    reset: resetForm,
    formState: { errors }
  } = form

  const values = watch()

  const inventoryClay = findInventoryClayBy('clayId', values.clayId)
  const isClayInInventory = !!inventoryClay
  const inventoryClayLot = values.inventoryClayLotsAttributes[0]
  const selectedBrand = useCallback(id => findBrand(id), [findBrand])

  const requestStatus = () => {
    if (inventoryClayLot?.id) return updateInventoryClayLotStatus
    return isClayInInventory ? createInventoryClayLotStatus : postInventoryClayStatus
  }

  const onSubmit = (data: InventoryClayFormValues) => {
    const inventoryClayPayload = { inventory_clay: serialize(data) }
    const inventoryClayLotPayload = { inventory_clay_lot: serialize(inventoryClayLot) }
    const positionalParams = {
      inventoryClayId: inventoryClay?.id,
      inventoryClayLotId: inventoryClayLot.id
    }

    if (inventoryClayLot?.id) {
      return updateInventoryClayLot(inventoryClayLotPayload, {}, positionalParams)
    }
    if (isClayInInventory) {
      return createInventoryClayLot(inventoryClayLotPayload, {}, positionalParams)
    }
    postInventoryClay(inventoryClayPayload)
  }

  const onRequestSuccess = () => {
    onSuccess()
    fetchClayFieldsInventoryClays()
    resetForm()
  }

  const handleChangeInventoryClayLot = option => {
    if (!option) return

    if (option?.newOption) {
      setValue('inventoryClayLotsAttributes.0.id', '')
      setValue('inventoryClayLotsAttributes.0.code', option.id)
      return
    }
    setValue('inventoryClayLotsAttributes.0.id', option.id)
    setValue('inventoryClayLotsAttributes.0.code', option.code)
  }

  const getInventoryClayWeightOptions = () => {
    return selectedBrand(values.brandId)?.packageWeights.map(weight => ({
      id: weight,
      label: formatWeight(weight)
    }))
  }

  return (
    <FormDialog
      submitLabel={t('actions.add')}
      title={t('inventory-clays:create')}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onRequestSuccess}
      requestStatus={requestStatus()}
      maxWidth="sm"
      content={
        <>
          <InventoryClayFormFields form={form} disabled={!isEmpty(initialValues)} />

          <InputGroup>
            <AutocompleteWithAddOption
              name="inventoryClayLotsAttributes[0].weight"
              label={`${t('inventory-clay-lot:weight')} (g)`}
              placeholder={`${t('inventory-clay-lot:weight')} (g)`}
              control={control}
              variant="standard"
              errorMessage={errors.inventoryClayLotsAttributes?.[0]?.weight?.message}
              options={getInventoryClayWeightOptions()}
            />
            {inventoryClay ? (
              <AutocompleteWithAddOption
                name="inventoryClayLotsAttributes[0].code"
                label={t('inventory-clay-lot:resource-short')}
                placeholder={t('inventory-clay-lot:resource-short')}
                control={control}
                variant="standard"
                options={inventoryClay?.inventoryClayLots}
                optionLabel="code"
                errorMessage={errors.inventoryClayLotsAttributes?.[0]?.code?.message}
                onChange={(_, option) => handleChangeInventoryClayLot(option)}
              />
            ) : (
              <Input
                name="inventoryClayLotsAttributes[0].code"
                type="text"
                label={t('inventory-clay-lot:resource-short')}
                placeholder={t('inventory-clay-lot:resource-short')}
                control={control}
                variant="standard"
                errorMessage={errors.inventoryClayLotsAttributes?.[0]?.code?.message}
              />
            )}
          </InputGroup>
        </>
      }
      {...rest}
    />
  )
}
