import styled from 'styled-components'

import { BREAKPOINTS } from 'styles/variables'

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
export const ListItem = styled.li`
  width: 100%;
  & + & {
    margin-top: 32px;
  }
`
export const VideoThumbIFrame = styled.iframe`
  flex: 1;
  height: 100%;
`
export const ChannelLink = styled.a`
  color: ${({ theme }) => theme.custom.accent};
`
export const videoThumbContainerStyles = {
  height: 240,
  display: 'flex',
  flexWrap: 'wrap',
  gap: 2,
  frameBorder: 0,
  mt: 2,

  [`@media (max-width: ${BREAKPOINTS.xs}px)`]: {
    height: 'initial'
  }
}
