import { useEffect } from 'react'
import { t } from 'i18next'
import { useOutletContext } from 'react-router-dom'

import { EmptyState, Pagination } from 'components'
import { useInventoryCustomClaysContext } from 'contexts'
import { usePagination } from 'hooks'

import { pageEmptyStateStyles } from 'styles'

import { ClayListContainer } from '../styles'
import { PageMainButtonProps, PageMainButtonOutletContextProps } from '../types'
import { InventoryCustomClayCard } from './components'

export const InventoryCustomClaysPage = ({ pageMainButton }: PageMainButtonProps) => {
  const { fetchInventoryCustomClays, inventoryCustomClays, fetchInventoryCustomClaysMeta } =
    useInventoryCustomClaysContext()

  const { setPageMainButton } = useOutletContext<PageMainButtonOutletContextProps>()

  const { page } = usePagination()
  const { totalPages } = fetchInventoryCustomClaysMeta

  useEffect(() => {
    fetchInventoryCustomClays({}, { page })
  }, [page]) // eslint-disable-line

  useEffect(() => {
    setPageMainButton(pageMainButton)
  }, []) // eslint-disable-line

  if (!inventoryCustomClays) {
    return <>{t('loading')}</>
  }

  return (
    <>
      <ClayListContainer>
        {inventoryCustomClays.length ? (
          inventoryCustomClays.map(inventoryCustomClay => (
            <InventoryCustomClayCard
              inventoryCustomClay={inventoryCustomClay}
              key={inventoryCustomClay.id}
              width="calc(20% - 8px)"
              showAvailableWeight
            />
          ))
        ) : (
          <EmptyState message={t('inventory-custom-clays:empty-state')} styles={pageEmptyStateStyles} />
        )}
      </ClayListContainer>

      <Pagination totalPages={totalPages} />
    </>
  )
}
