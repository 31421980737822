import { ReactNode } from 'react'
import { useStorageCurrentUser } from 'hooks'
import { CurrentUserContext } from 'contexts'

type Props = {
  children: ReactNode
}

export const CurrentUserProvider = ({ children }: Props) => {
  return <CurrentUserContext.Provider value={useStorageCurrentUser()}>{children}</CurrentUserContext.Provider>
}
