import styledComponent, { CSSObject } from 'styled-components'
import { DefaultTheme, styled } from '@mui/material/styles'
import { Tooltip, tooltipClasses } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'

type ContainerProps = {
  styles: CSSObject
} & DefaultTheme

export const Container = styledComponent.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ styles }) => styles}
`

export const Error = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.custom.invertedBase.text,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '0.5rem'
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.custom.invertedBase.text,
    boxShadow: theme.shadows[1]
  }
}))

export const AlertIcon = styled(ErrorOutline)({
  position: 'absolute',
  right: 26,
  top: '50%',
  transform: 'translateY(-50%)'
})
