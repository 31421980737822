import { useEffect } from 'react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { Project } from 'models'
import { ProjectEndpoint } from 'routes/locations'

export const useProjectController = (projectId: Id) => {
  const [fetchProject, project, , , , fetchProjectMeta] = useApi<Project>(
    ProjectEndpoint,
    'GET',
    { projectId },
    Project
  )
  const [updateProject, , updateProjectError, , updateProjectStatus] = useApi<Project>(
    ProjectEndpoint,
    'PUT',
    { projectId }
  )
  const [destroyProject, , destroyProjectError, , destroyProjectStatus] = useApi<Project>(
    ProjectEndpoint,
    'DELETE',
    { projectId }
  )

  const { showToast } = useToastContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!updateProjectError) return

    showToast({
      type: 'error',
      title: t('project:errors:create:title'),
      description: updateProjectError.message
    })
  }, [updateProjectError, showToast])

  useEffect(() => {
    if (updateProjectStatus !== 200) return

    showToast({
      type: 'success',
      title: t('project:success:create:title'),
      description: t('project:success:create:description')
    })
  }, [updateProjectStatus, showToast])

  useEffect(() => {
    if (destroyProjectStatus !== 204) return
    navigate('..')
  }, [destroyProjectStatus, navigate])

  useEffect(() => {
    if (!destroyProjectError) return

    showToast({
      type: 'success',
      title: t('project:errors:destroy:title'),
      description: destroyProjectError.message
    })
  }, [destroyProjectError, showToast])

  return {
    project,
    fetchProject,
    updateProject,
    updateProjectStatus,
    fetchProjectMeta,
    destroyProject
  }
}
