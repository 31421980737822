import { useEffect, useState } from 'react'
import { t } from 'i18next'

import { EmptyState, PageContainer, Pagination } from 'components'
import { useProjectsContext } from 'contexts'
import { ProjectFormDialog } from '../components'
import { usePagination } from 'hooks'
import { ProjectsProvider } from 'providers'
import { pageEmptyStateStyles } from 'styles'

import { ProjectListCard } from './components'
import { Button, List } from './styles'

const ProjectsPageFC = () => {
  const { fetchProjects, projects, fetchProjectsMeta, postProject, postProjectStatus } = useProjectsContext()

  const [isProjectFormDialogOpen, setIsProjectFormDialogOpen] = useState<boolean>(false)

  const { page } = usePagination()
  const { totalPages } = fetchProjectsMeta

  useEffect(() => {
    fetchProjects({}, { page })
  }, [page]) // eslint-disable-line

  const handleOpenModal = () => {
    setIsProjectFormDialogOpen(prevState => !prevState)
  }

  const handleClose = () => {
    setIsProjectFormDialogOpen(false)
  }

  if (!projects) {
    return <>{t('loading')}</>
  }

  return (
    <PageContainer flexColumn>
      <Button onClick={handleOpenModal}>{t('projects:create')}</Button>

      {projects?.length ? (
        <List>
          {projects?.map(project => (
            <ProjectListCard project={project} key={project.id} />
          ))}
        </List>
      ) : (
        <EmptyState message={t('projects:empty-state')} styles={pageEmptyStateStyles} />
      )}

      <Pagination totalPages={totalPages} />

      <ProjectFormDialog
        open={isProjectFormDialogOpen}
        onClose={handleClose}
        onSuccess={fetchProjects}
        submitLabel={t('actions.create')}
        submitAction={postProject}
        requestStatus={postProjectStatus}
      />
    </PageContainer>
  )
}

export const ProjectsPage = () => (
  <ProjectsProvider>
    <ProjectsPageFC />
  </ProjectsProvider>
)
