import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { Card, CardMedia, CardContent, Typography } from '@mui/material'

import { TypographyEllipsis } from 'components'
import { InventoryClay } from 'models'
import { cardContentStyles, cardStyles } from './styles'

type Props = {
  inventoryClay: InventoryClay
}

export const InventoryClayCard = ({ inventoryClay }: Props) => {
  return (
    <Card sx={cardStyles} key={inventoryClay.id} component={Link} to={`clays/${inventoryClay.id}`}>
      <CardMedia
        component="img"
        image={inventoryClay.clay.photoUrl}
        alt={inventoryClay.clay.color}
        sx={{ height: 140 }}
      />
      <CardContent sx={cardContentStyles}>
        <TypographyEllipsis lineClamp={2} variant="h5" gutterBottom>
          {inventoryClay.clay.color}
        </TypographyEllipsis>
        <div>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {inventoryClay.clay.brand.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>{t('inventory-clay:weight')}</strong> {inventoryClay.weight}g
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}
