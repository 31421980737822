import styled from 'styled-components'

import { BREAKPOINTS } from 'styles/variables'

export const ClayContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  gap: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const SelectGroup = styled.div`
  display: flex;
  gap: 8px;

  > div {
    flex: 1;
    margin: 0 4px;
  }
`
export const InputGroup = styled.div`
  display: flex;
  gap: 8px;

  > div {
    margin: 0 4px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-wrap: wrap;
  }
`
export const descriptionInputStyles = {
  flex: 2,
  [`@media (max-width: ${BREAKPOINTS.xs}px)`]: {
    flex: '2 0 100%',
    order: 2
  }
}

export const customClayDescriptionInputStyles = {
  width: '50%',
  [`@media (max-width: ${BREAKPOINTS.xs}px)`]: {
    width: '100%'
  }
}
