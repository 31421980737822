import { useState } from 'react'
import { t } from 'i18next'
import { Button } from '@mui/material'

import { useInventoryCustomClaysContext } from 'contexts'

import { CreateInventoryCustomClayFormDialog } from '..'

export const CreateInventoryCustomClayButtonForm = () => {
  const { fetchInventoryCustomClays } = useInventoryCustomClaysContext()
  const [isFormDialogOpen, setIsFormDialog] = useState<boolean>(false)

  const handleButtonClick = () => {
    setIsFormDialog(true)
  }

  const handleClose = () => {
    setIsFormDialog(false)
  }

  return (
    <>
      <Button onClick={handleButtonClick} sx={{ alignSelf: 'flex-end' }}>
        {t('inventory-custom-clays:create')}
      </Button>

      <CreateInventoryCustomClayFormDialog
        open={isFormDialogOpen}
        onClose={handleClose}
        onSuccess={fetchInventoryCustomClays}
      />
    </>
  )
}
