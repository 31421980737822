import { Project, InventoryClayLot, InventoryCustomClay } from 'models'
import { classify } from 'utils'

export class ProjectInventoriableClay {
  id: Id
  description: string
  weight: number

  project: Project
  inventoriableClay: InventoryClayLot | InventoryCustomClay
  inventoriableClayType: 'InventoryClayLot' | 'InventoryCustomClay'

  constructor(projectInventoriableClayParams) {
    const { inventoriableClay } = projectInventoriableClayParams
    const inventoriableClayType = classify(inventoriableClay.type)

    Object.assign(this, {
      ...projectInventoriableClayParams,
      inventoriableClayType
    })
  }
}
