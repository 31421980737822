import { Box, Card, CardMedia, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

import { EmptyState, Menu, PhotoPlaceholder } from 'components'
import { InventoryClayLot } from 'models'
import { sliceAt } from 'utils'

import { getInventoryClayLotCardMenuProps } from './lookups'
import { lotCardStyles, noProjectLotEmptyStateStyles, projectPhotosBoxStyles } from './styles'

type Props = {
  inventoryClayLot: InventoryClayLot
  onMenuClick: (inventoryClayLotId: string) => void
}

export const InventoryClayLotCard = ({ inventoryClayLot, onMenuClick }: Props) => {
  return (
    <Card key={inventoryClayLot.id} sx={lotCardStyles}>
      <Menu
        sx={{ position: 'absolute', top: 4, right: 4, zIndex: 10 }}
        items={getInventoryClayLotCardMenuProps([
          {
            id: 'delete',
            onClick: () => onMenuClick(inventoryClayLot.id)
          }
        ])}
      />

      <Box sx={projectPhotosBoxStyles}>
        {inventoryClayLot.projectInventoriableClays?.length ? (
          sliceAt(inventoryClayLot.projectInventoriableClays, 0, 4)?.map(projectInventoriableClay => {
            const { project } = projectInventoriableClay
            const projectPhoto = project.projectPhotos?.[0]?.imageUrl
            return (
              <Link
                to={`/projects/${projectInventoriableClay.project.id}`}
                key={projectInventoriableClay.id}
                style={{ width: '50%' }}
              >
                {projectPhoto ? (
                  <CardMedia component="img" image={projectPhoto} alt={project.title} sx={{ height: 70 }} />
                ) : (
                  <PhotoPlaceholder
                    key={projectInventoriableClay.id}
                    sx={{ padding: '0 12px 12px', height: 70 }}
                  />
                )}
              </Link>
            )
          })
        ) : (
          <EmptyState message={t('inventory-clay-lot:empty-state')} styles={noProjectLotEmptyStateStyles} />
        )}
      </Box>
      <Typography variant="body2" color="text.secondary">
        <strong>{t('inventory-clay-lot:resource-short')}:</strong> #{inventoryClayLot.code}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <strong>{t('inventory-clay-lot:weight')}:</strong> {inventoryClayLot.weight}g
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <strong>{t('inventory-clay-lot:available-weight')}:</strong> {inventoryClayLot.availableWeight}g
      </Typography>
    </Card>
  )
}
