import { useState, useEffect, ChangeEvent } from 'react'
import { t } from 'i18next'
import { Box, Typography } from '@mui/material'
import * as Yup from 'yup'

import { PageContainer, PhotoPlaceholder, Menu } from 'components'
import { useToastContext, useProjectContext } from 'contexts'
import { InventoryClayLot, InventoryCustomClay } from 'models'
import { ProjectProvider } from 'providers'

import { InventoryCustomClayCard } from 'pages/inventory/InventoryCustomClaysPage/components'
import { InventoryClayLotCard } from 'pages/inventory/components'

import { ProjectFormDialog } from '../components'
import { DeleteProjectDialog } from './components'
import {
  Aside,
  ProjectContainer,
  ClayListContainer,
  ProjectPhotoEmptyState,
  FileInput,
  ProjectPhoto
} from './styles'

import { getProjectMenuProps } from './lookups'

const ACCEPTED_IMAGE_FORMATS = ['image/jpeg', 'image/png']

const photosFormSchema = Yup.object().shape({
  files: Yup.mixed()
    .required('A photo is required')
    .test('fileSize', 'File too large', (files: File[]) => {
      return Array.from(files).every(file => file && file.size <= 1048576)
    })
    .test('fileFormat', 'Unsupported Format', (files: File[]) => {
      return Array.from(files).every(file => file && ACCEPTED_IMAGE_FORMATS.includes(file.type))
    })
})

export const ProjectPageFC = () => {
  const { fetchProject, project, updateProject, updateProjectStatus, destroyProject } = useProjectContext()
  const [isUpdateProjectDialogOpen, setIsUpdateProjectDialogOpen] = useState<boolean>(false)
  const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] = useState<boolean>(false)

  const { showToast } = useToastContext()

  useEffect(() => {
    fetchProject()
  }, []) // eslint-disable-line

  const handleEditProject = () => {
    setIsUpdateProjectDialogOpen(prevState => !prevState)
  }

  const handleDeleteProject = () => {
    setIsDeleteProjectDialogOpen(prevState => !prevState)
  }

  const handleClose = () => {
    setIsUpdateProjectDialogOpen(false)
    setIsDeleteProjectDialogOpen(false)
  }

  const handleSubmitPhotos = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files } = e.target
      photosFormSchema.validateSync({ files })
      const formData = new FormData()
      Array.from(files).map(file => {
        return formData.append('project[project_photos_attributes][][image]', file)
      })
      await updateProject(formData)
      fetchProject()
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Photo update error!',
        description: error.message
      })
    }
  }

  if (!project) {
    return <>{t('loading')}</>
  }

  return (
    <PageContainer flex>
      <Box sx={{ position: 'absolute', top: 16, right: 4, zIndex: 10 }}>
        <Menu
          items={getProjectMenuProps([
            {
              id: 'edit',
              onClick: handleEditProject
            },
            {
              id: 'delete',
              onClick: handleDeleteProject
            }
          ])}
        />
      </Box>
      <Aside>
        {project.projectPhotos?.map(projectPhoto => (
          <ProjectPhoto src={projectPhoto.imageUrl} key={projectPhoto.id} />
        ))}
        <ProjectPhotoEmptyState>
          <PhotoPlaceholder sx={{ width: '70%', height: 'unset' }} />

          <FileInput
            type="file"
            name="projectPhoto"
            inputProps={{ multiple: true }}
            onChange={handleSubmitPhotos}
          />
        </ProjectPhotoEmptyState>
      </Aside>

      <ProjectContainer>
        <Typography variant="h4">{project.title}</Typography>

        <Typography variant="body1" borderBottom={0.1} sx={{ py: 0.5, mt: 3 }}>
          {t('project:settings:height')}: {project.settings.height} cm
        </Typography>
        <Typography variant="body1" borderBottom={0.1} sx={{ py: 0.5 }}>
          {t('project:settings:width')}: {project.settings.width} cm
        </Typography>
        <Typography variant="body1" borderBottom={0.1} sx={{ py: 0.5 }}>
          {t('project:settings:weight')}: {project.settings.weight} g
        </Typography>

        <ClayListContainer>
          {project.projectInventoriableClays?.map(projectInventoriableClay =>
            (projectInventoriableClay.inventoriableClay as InventoryClayLot).inventoryClay ? (
              <InventoryClayLotCard
                key={projectInventoriableClay.id}
                inventoryClayLot={projectInventoriableClay.inventoriableClay as InventoryClayLot}
                weight={projectInventoriableClay.weight}
                description={projectInventoriableClay.description}
                width="50%"
                mediaStyles={{ width: '100px', height: '130px' }}
              />
            ) : (
              <InventoryCustomClayCard
                key={projectInventoriableClay.id}
                inventoryCustomClay={projectInventoriableClay.inventoriableClay as InventoryCustomClay}
                width="calc(50% - 16px)"
                portrait
              />
            )
          )}
        </ClayListContainer>

        <Typography variant="body1" sx={{ mt: 2 }}>
          {t('project:notes')}: {project.description}
        </Typography>
      </ProjectContainer>

      <ProjectFormDialog
        open={isUpdateProjectDialogOpen}
        onClose={handleClose}
        onSuccess={fetchProject}
        project={project}
        submitLabel={t('actions.update')}
        submitAction={updateProject}
        requestStatus={updateProjectStatus}
      />

      <DeleteProjectDialog
        open={isDeleteProjectDialogOpen}
        onClose={() => setIsDeleteProjectDialogOpen(false)}
        onSubmit={destroyProject}
      />
    </PageContainer>
  )
}

export const ProjectPage = () => {
  return (
    <ProjectProvider>
      <ProjectPageFC />
    </ProjectProvider>
  )
}
