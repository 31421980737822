import { createContext, useContext } from 'react'

import { useClayFieldsController } from 'controllers'

export const ClayFieldsContext = createContext<ReturnType<typeof useClayFieldsController> | null>(null)

export const useClayFieldsContext = () => {
  const context = useContext(ClayFieldsContext)
  const errorMessage = 'useClayFieldsContext must be used within a ClayFieldsProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
