import { Container } from './styles'

import './styles.css'

type Props = {
  small: boolean
}

export const DotLoader = ({ small }: Props) => {
  return (
    <Container $small={small} className="spinner dot-loader">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </Container>
  )
}
