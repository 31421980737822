import { createContext, useContext } from 'react'
import { useStorageCurrentUser } from 'hooks'

export const CurrentUserContext = createContext<ReturnType<typeof useStorageCurrentUser> | null>(null)

export const useCurrentUserContext = () => {
  const context = useContext(CurrentUserContext)
  const errorMessage = 'useCurrentUserContext must be used within a CurrentUserProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
