import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { useClayFieldsContext, useInventoryCustomClaysContext } from 'contexts'
import { serializeAll } from 'utils'

import { getMixedColor } from '../components/InventoryCustomClayFormFields/utils'
import {
  inventoryCustomClaySchema,
  InventoryCustomClayFormValues
} from '../components/CreateInventoryCustomClayForm'

export const useInventoryCustomClayForm = () => {
  const { inventoryClays } = useClayFieldsContext()

  const { postInventoryCustomClay } = useInventoryCustomClaysContext()

  const inventoryClayIds = inventoryClays?.map(({ clay }) => clay.id) || []

  const form = useForm<InventoryCustomClayFormValues>({
    resolver: yupResolver(inventoryCustomClaySchema()),
    context: { inventoryClayIds }
  })

  const onSubmit = (data: InventoryCustomClayFormValues) => {
    const claysData = data.clays.map(clay => {
      const { inventoryClayLotId, weight } = clay
      return { inventoryClayLotId, weight }
    })
    const payload = {
      name: data.name,
      hex_color: getMixedColor(data.clays),
      inventory_custom_clay_weights_attributes: serializeAll(claysData)
    }
    postInventoryCustomClay(payload)
  }

  return {
    form,
    onSubmit: form.handleSubmit(onSubmit)
  }
}
