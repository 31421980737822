import * as Yup from 'yup'
import { t } from 'i18next'

import { numberTextFieldYupPatch } from 'utils'

export const inventoryCustomClaySchema = () =>
  Yup.object().shape({
    name: Yup.string().required().label(t('inventory-custom-clay:name')),
    clays: Yup.array().of(
      Yup.object({
        weight: Yup.number()
          .min(1)
          .required()
          .transform(numberTextFieldYupPatch)
          .label(t('inventory-clay:weight')),
        brandId: Yup.string().required().label(t('brand:resource')),
        inventoryClayLotId: Yup.string().required().label(t('inventory-clay-lot:resource-short')),
        hexColor: Yup.string(),
        clayId: Yup.string()
          .required()
          .label(t('clay:color'))
          .test(
            'is-in-inventory',
            () => t('clay:errors.not-found'),
            (value, { options }) => options.context.inventoryClayIds.includes(value)
          )
      })
    )
  })
