import { useState, useContext } from 'react'
import axios, { Method, AxiosResponse } from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { deserialize } from 'deserialize-json-api'
import Location from 'app-location'

import { CurrentUserContext } from 'contexts'
import { AUTHORIZATION_KEY, ApiError, ApiCall } from 'hooks'
import { User } from 'models'
import { getHeaders } from './useApi/utils'

export const useAuth = (
  endpoint: Location,
  method: Method = 'POST'
): [ApiCall<User>, ApiError, boolean, number] => {
  const navigate = useNavigate()
  const location = useLocation()

  const { setCurrentUser } = useContext(CurrentUserContext)

  const [error, setError] = useState<ApiError>()
  const [loading, setLoading] = useState<boolean>()
  const [status, setStatus] = useState<number>()

  const call = async body => {
    const apiUrl = process.env.REACT_APP_API_URL
    const url = `${apiUrl}${endpoint.toUrl()}`
    setLoading(true)

    try {
      const response: AxiosResponse<User> = await axios({ method, url, data: body, headers: getHeaders() })
      const token = response?.headers?.authorization || ''
      const user = deserialize(response.data, { transformKeys: 'camelCase' }) as AxiosResponse<User>

      localStorage.setItem(AUTHORIZATION_KEY, token)
      setCurrentUser(user?.data)
      setStatus(response.status)
      setLoading(false)

      navigate(location.state?.from || '/')

      return await Promise.resolve(response.data)
    } catch (e) {
      setError({ message: e.response.data.errors || e.response?.data.error })
      setStatus(e.response.status)
      setLoading(false)
    }
  }

  return [call, error, loading, status]
}
