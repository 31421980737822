export const markClaysToDestroy = (projectInventoriableClays, formClays) => {
  const markedToDestroy =
    projectInventoriableClays
      ?.map(projectInventoriableClay => {
        const isClayRemoved = !formClays.find(clay => clay.id === projectInventoriableClay.id)

        if (isClayRemoved) {
          return { id: projectInventoriableClay.id, _destroy: true }
        }
      })
      .filter(Boolean) || []

  return [...markedToDestroy, ...formClays]
}
