import { createContext, useContext } from 'react'

import { useInventoryCustomClayController } from 'controllers'

export const InventoryCustomClayContext = createContext<ReturnType<
  typeof useInventoryCustomClayController
> | null>(null)

export const useInventoryCustomClayContext = () => {
  const context = useContext(InventoryCustomClayContext)
  const errorMessage = 'useInventoryCustomClayContext must be used within a InventoryCustomClayProvider'

  if (!context) throw new Error(errorMessage)

  return context
}
