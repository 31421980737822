import styled from 'styled-components'

export const Aside = styled.aside`
  width: 250px;
  border-radius: 20px;

  ul li:last-child a {
    border-bottom: none;
  }
`
export const linkStyles = {
  width: '100%',
  background: 'rgba(197, 17, 98, 0.04)',
  px: '24px',
  justifyContent: 'flex-start',
  borderBottom: '1px solid rgba(197, 17, 98, 0.07)',

  '&:hover': {
    background: 'rgba(197, 17, 98, 0.07)'
  }
}
