import styled from 'styled-components'
import { DefaultTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { colors } from 'styles/colors'

export const Container = styled.header`
  top: 0;
  height: 70px;
  padding: 0 32px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  box-shadow: 1px 2px 20px ${({ theme }) => theme.custom.card.shadow};
  background: ${({ theme }) => theme.custom.topbar.bg};
  z-index: 1000;

  a {
    color: ${({ theme }) => theme.custom.topbar.text};

    &:hover {
      color: ${({ theme }) => theme.custom.topbar.hover.text};
    }

    h1 {
      font-family: Montserrat, sans-serif;
      font-weight: 300;
    }
  }

  @media (max-width: 450px) {
    padding: 0 24px;

    a h1 {
      font-size: 22px;
    }
  }
`

type MenuContainerProps = {
  $hasAvatar?: boolean
} & DefaultTheme

export const MenuContainer = styled.div<MenuContainerProps>`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  transform: scale(1);
  transition: transform 0.2s ease-in, border-color 0.05s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: inherit;
    object-fit: cover;
  }
`
export const LogoLink = styled(Link)`
  display: inline-block;
  height: 50px;
  width: 140px;

  svg {
    width: 100%;
    height: 100%;
  }
`
export const UnconfirmedEmailBanner = styled.div`
  background: ${colors.red};
  color: ${colors.offWhite};
  left: 0;
  position: absolute;
  width: 100%;
  height: 50px;
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
