import { useState } from 'react'
import Location from 'app-location'
import axios, { Method, AxiosResponse } from 'axios'
import { deserialize as deserializeJsonApi } from 'deserialize-json-api'

import { ApiError, ApiCall } from 'hooks'
import { deserialize } from 'utils'

import { getHeaders, handleUnauthorized } from './utils'

type Meta = {
  page: number
  per: number
  total: number
  totalPages: number
}

const INITIAL_VALUES = {
  loading: false,
  meta: {} as Meta
} as const

export const useApi = <T>(
  path: Location,
  method?: Method,
  positionalParams?: object,
  model?: new (...args: T[]) => T
): [ApiCall<AxiosResponse<T>>, T, ApiError, boolean, number, Meta] => {
  const [result, setResult] = useState<T>()
  const [status, setStatus] = useState<number>()
  const [error, setError] = useState<ApiError>()
  const [loading, setLoading] = useState<boolean>(INITIAL_VALUES.loading)
  const [meta, setMeta] = useState<Meta>(INITIAL_VALUES.meta)

  const call = async (body, queryParams, overlapPositionalParams) => {
    reset()

    const combinedParams = { ...positionalParams, ...overlapPositionalParams }
    const url = `${process.env.REACT_APP_API_URL}${path.toUrl(combinedParams)}`

    setLoading(true)

    try {
      const response: AxiosResponse<T> = await axios({
        method: method || 'GET',
        url,
        data: body,
        params: queryParams,
        headers: getHeaders(),
        validateStatus
      })

      const normalizedData = deserializeJsonApi(response.data, { transformKeys: 'camelCase' })
      setResult(buildResult(normalizedData.data, model))
      setStatus(response.status)
      setMeta(deserialize(normalizedData.meta))
      setError(null)
      setLoading(false)

      return response
    } catch (e) {
      const { response } = e
      handleUnauthorized(response?.status)
      const message = response?.data.errors || response?.data.error || response?.data

      setStatus(response?.status)
      setError({ message })
      setLoading(false)
      console.log(e)

      return response
    }
  }

  const reset = () => {
    setStatus(undefined)
    setError(undefined)
    setLoading(INITIAL_VALUES.loading)
    setMeta(INITIAL_VALUES.meta)
  }

  return [call, result, error, loading, status, meta]
}

const validateStatus = (requestStatus: number) => {
  return requestStatus >= 200 && requestStatus <= 302
}

const buildResult = <T>(data: T | T[], model?: new (...args: T[]) => T) => {
  if (Array.isArray(data)) {
    return data.map(record => (model ? new model(record) : record))
  }
  return model ? new model(data) : data
}
