import { MenuItemProps } from 'components'

import { projectMenuItems } from './constants'

export const getProjectMenuProps = (props?: MenuItemProps[]) => {
  if (!props) return projectMenuItems()

  return projectMenuItems().map(menuItem => {
    const prop = props.find(({ id }) => id === menuItem.id)
    return { ...menuItem, ...prop }
  })
}
