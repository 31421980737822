import { useCallback, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { t } from 'i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EmailOutlined, LockOutlined } from '@mui/icons-material'

import { Button, Input } from 'components'
import { useToastContext } from 'contexts'
import { useAuth } from 'hooks'
import { SignInEndpoint } from 'routes'

import { Container, Title, FormContainer, Form, Link, Footer } from './styles'

const schema = Yup.object().shape({
  email: Yup.string().required().email().label(t('user:email')),
  password: Yup.string().required().label(t('user:password'))
})

export const SignIn = () => {
  const [signIn, error, loading] = useAuth(SignInEndpoint)
  const { showToast } = useToastContext()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onSubmit = useCallback(
    data => {
      signIn({ user: data })
    },
    [signIn]
  )

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: t('sign-in:errors.default'),
      description: error.message
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>{t('sign-in:title')}</Title>
          <Input
            type="email"
            name="email"
            label={t('user:email')}
            placeholder={t('user:email')}
            icon={EmailOutlined}
            control={control}
            autoComplete="username"
            errorMessage={errors.email?.message as string}
          />

          <Input
            type="password"
            name="password"
            label={t('user:password')}
            placeholder={t('user:password')}
            icon={LockOutlined}
            control={control}
            autoComplete="current-password"
            errorMessage={errors.password?.message as string}
            styles={{ marginTop: '0.8rem' }}
          />

          <Button
            type="submit"
            label={t('actions.submit')}
            styles={{ marginTop: '1rem' }}
            loading={loading}
          />

          <Footer>
            <Link to="/forgot-password" style={{ opacity: 0.7 }}>
              {t('forgot-password:title')}
            </Link>
            <Link to="/sign-up">{t('sign-up:create-account')}</Link>
          </Footer>
        </Form>
      </FormContainer>
    </Container>
  )
}
