import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { InventoryClay } from 'models'
import { InventoryClayEndpoint } from 'routes/locations'

export const useInventoryClayController = (inventoryClayId: Id) => {
  const navigate = useNavigate()
  const { showToast } = useToastContext()

  const [fetchInventoryClay, inventoryClay, , , fetchInventoryClayStatus] = useApi<InventoryClay>(
    InventoryClayEndpoint,
    'GET',
    { inventoryClayId },
    InventoryClay
  )
  const [updateInventoryClay, , updateInventoryClayError, , updateInventoryClayStatus] =
    useApi<InventoryClay>(InventoryClayEndpoint, 'PUT', { inventoryClayId })

  useEffect(() => {
    if (fetchInventoryClayStatus !== 404) return
    navigate('/inventory')
  }, [fetchInventoryClayStatus, navigate])

  useEffect(() => {
    if (updateInventoryClayStatus !== 200) return

    showToast({
      type: 'success',
      title: t('inventory-clay:success:update:title'),
      description: t('inventory-clay:success:update:description')
    })
  }, [updateInventoryClayStatus, showToast])

  useEffect(() => {
    if (!updateInventoryClayError) return

    showToast({
      type: 'error',
      title: t('inventory-clay:errors:update:title'),
      description: updateInventoryClayError.message
    })
  }, [updateInventoryClayError, showToast])

  return {
    inventoryClay,
    fetchInventoryClay,
    updateInventoryClay,
    updateInventoryClayStatus
  }
}
