import { InventoryClayLot, InventoryCustomClay } from 'models'

export class InventoryCustomClayWeight {
  id: Id
  weight: number
  inventoryClayLotId: Id

  inventoryClayLot: InventoryClayLot
  inventoryCustomClay: InventoryCustomClay

  constructor(inventoryCustomClayWeightParams) {
    const { inventoryCustomClay: inventoryCustomClayParams } = inventoryCustomClayWeightParams
    const inventoryCustomClay =
      inventoryCustomClayParams?.map(params => new InventoryCustomClay(params)) || []

    Object.assign(this, {
      ...inventoryCustomClayWeightParams,
      inventoryCustomClay
    })
  }
}
