import { object, string, number, array, InferType } from 'yup'

import { numberTextFieldYupPatch } from 'utils'

export const projectSchema = object().shape({
  id: string(),
  title: string().required().label('Title'),
  settings: object({
    height: number().min(1).nullable().transform(numberTextFieldYupPatch).label('Height'),
    width: number().min(1).nullable().transform(numberTextFieldYupPatch).label('Width'),
    weight: number().min(1).nullable().transform(numberTextFieldYupPatch).label('Weight')
  }),
  description: string().label('Description'),
  clays: array().of(
    object({
      id: string(),
      weight: number().nullable().min(0).transform(numberTextFieldYupPatch).label('Weight'),
      description: string().label('Description'),
      inventoriableClayId: string().required().label('Lot'),
      inventoriableClayType: string(),
      brandId: string()
        .label('Brand')
        .when('inventoriableClayType', {
          is: 'InventoryClayLot',
          then: schema => schema.required()
        }),
      inventoryClayId: string()
        .label('Color')
        .when('inventoriableClayType', {
          is: 'InventoryClayLot',
          then: schema => schema.required()
        })
    })
  )
})

export type ProjectFormValues = InferType<typeof projectSchema>
