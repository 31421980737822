import { createFilterOptions } from '@mui/material'

const filter = createFilterOptions()

export const filterOptions = (optionList, params, optionLabel = 'label') => {
  const filtered = filter(optionList, params)

  const { inputValue } = params
  const isExisting = optionList.some(option => inputValue === option[optionLabel])

  if (inputValue !== '' && !isExisting) {
    filtered.push({
      id: inputValue,
      inputValue,
      [optionLabel]: `Add ${inputValue}`,
      newOption: true
    })
  }

  return filtered
}
