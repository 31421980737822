import { inventoriableClayCardMobileStyles } from 'pages/inventory/components/styles'

export const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  ...inventoriableClayCardMobileStyles
}

export const cardContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1
}
