import { InventoryClayLot, ProjectInventoriableClay } from 'models'
import { ProjectFormValues } from '../../ProjectFormDialog/schema'

export const getProjectInventoriableClayInitialValues = (
  projectInventoriableClays: ProjectInventoriableClay[]
) => {
  return projectInventoriableClays?.map(projectInventoriableClay => {
    const { id, description, weight, inventoriableClay } = projectInventoriableClay
    const isInventoryClayLot = !!(inventoriableClay as InventoryClayLot).inventoryClay

    let projectInitialValues: ProjectFormValues['clays'][number] = {
      id,
      weight,
      description,
      inventoriableClayId: inventoriableClay.id,
      inventoriableClayType: isInventoryClayLot ? 'InventoryClayLot' : 'InventoryCustomClay'
    }
    // TODO: instantiate models and add this check there
    if (isInventoryClayLot) {
      const {
        inventoryClay: {
          id: inventoryClayId,
          clay: { brandId }
        }
      } = inventoriableClay as InventoryClayLot

      projectInitialValues = {
        ...projectInitialValues,
        inventoryClayId,
        brandId
      }
    }

    return projectInitialValues
  })
}
