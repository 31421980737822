import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'

import { InventoryClayContext } from 'contexts'
import { useInventoryClayController } from 'controllers'

type Props = {
  children: ReactNode
}

export const InventoryClayProvider = ({ children }: Props) => {
  const { inventoryClayId } = useParams()

  return (
    <InventoryClayContext.Provider value={useInventoryClayController(inventoryClayId)}>
      {children}
    </InventoryClayContext.Provider>
  )
}
