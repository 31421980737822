import { Typography } from '@mui/material'
import { t } from 'i18next'

import HeroImage from 'assets/images/homepage/homepage-hero.jpg'
import InventoryClaysImage from 'assets/images/homepage/inventory-clays.jpg'
import ColorMixerImage from 'assets/images/homepage/color-mixer.jpg'
import ProjectImage from 'assets/images/homepage/project.jpg'

import { PageContainer } from 'components'
import { ImgWrapper, HeroImg, Img } from './styles'

export const Home = () => {
  return (
    <PageContainer flex flexColumn gap="32px">
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        {t('homepage:welcome.title')}
      </Typography>
      <ImgWrapper>
        <HeroImg src={HeroImage} />
      </ImgWrapper>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
        {t('homepage:welcome.description')}
      </Typography>

      <Typography variant="h5">{t('homepage:topics:inventory-clays.title')}</Typography>
      <Img src={InventoryClaysImage} />
      <Typography variant="body1">{t('homepage:topics:inventory-clays.description')}</Typography>

      <Typography variant="h5">{t('homepage:topics:inventory-custom-clays.title')}</Typography>
      <Img src={ColorMixerImage} />
      <Typography variant="body1">{t('homepage:topics:inventory-custom-clays.description')}</Typography>

      <Typography variant="h5">{t('homepage:topics:projects.title')}</Typography>
      <Img src={ProjectImage} />
      <Typography variant="body1">{t('homepage:topics:projects.description')}</Typography>
    </PageContainer>
  )
}
