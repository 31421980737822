import Location from 'app-location'
import * as Yup from 'yup'

export const SignInEndpoint: Location = new Location('/users/sign_in')
export const SignOutEndpoint: Location = new Location('/users/sign_out')
export const ForgotPasswordEndpoint: Location = new Location('/users/password')
export const ResetPasswordEndpoint: Location = new Location('/users/password', null, {
  reset_password_token: Yup.string()
})
export const ConfirmEmailEndpoint: Location = new Location('/users/confirmation', null, {
  confirmation_token: Yup.string()
})
