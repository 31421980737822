import { Button, Dialog as MaterialDialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { t } from 'i18next'

import { ConfirmDialogProps } from './types'

export const ConfirmDialog = ({
  open,
  onClose,
  onSuccess,
  onSubmit,
  submitLabel,
  title,
  content,
  contentProps,
  ...rest
}: ConfirmDialogProps) => {
  const handleConfirm = async () => {
    const { status } = await onSubmit()
    const isSuccessfulRequest = status < 400
    if (isSuccessfulRequest) {
      onSuccess?.()
      onClose()
    }
  }

  return (
    <MaterialDialog open={open} onClose={onClose} maxWidth="xs" fullWidth {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent {...contentProps}>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('actions.cancel')}</Button>
        <Button onClick={handleConfirm}>{submitLabel}</Button>
      </DialogActions>
    </MaterialDialog>
  )
}
