import { use as i18nUse } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { LANGUAGE_KEY, DEFAULT_LANGUAGE, LANGUAGES } from 'hooks'

import * as en from './en'
import * as pt from './pt'
import * as es from './es'

export const resources = {
  en: {
    global: en.global,
    homepage: en.homepage,
    account: en.account,
    languages: en.languages,
    feedbacks: en.feedbacks,
    brands: en.brands,
    brand: en.brand,
    clays: en.clays,
    clay: en.clay,
    projects: en.projects,
    project: en.project,
    user: en.user,
    'inventory-clays': en.inventoryClays,
    'inventory-clay': en.inventoryClay,
    'inventory-clay-lots': en.inventoryClayLots,
    'inventory-clay-lot': en.inventoryClayLot,
    'inventory-custom-clays': en.inventoryCustomClays,
    'inventory-custom-clay': en.inventoryCustomClay,
    'sign-in': en.signIn,
    'sign-up': en.signUp,
    'forgot-password': en.forgotPassword,
    'reset-password': en.resetPassword
  },
  pt: {
    global: pt.global,
    homepage: pt.homepage,
    account: pt.account,
    feedbacks: pt.feedbacks,
    languages: pt.languages,
    brands: pt.brands,
    brand: pt.brand,
    clays: pt.clays,
    clay: pt.clay,
    projects: pt.projects,
    project: pt.project,
    user: pt.user,
    'inventory-clays': pt.inventoryClays,
    'inventory-clay': pt.inventoryClay,
    'inventory-clay-lots': pt.inventoryClayLots,
    'inventory-clay-lot': pt.inventoryClayLot,
    'inventory-custom-clays': pt.inventoryCustomClays,
    'inventory-custom-clay': pt.inventoryCustomClay,
    'sign-in': pt.signIn,
    'sign-up': pt.signUp,
    'forgot-password': pt.forgotPassword,
    'reset-password': pt.resetPassword
  },
  es: {
    global: es.global,
    homepage: es.homepage,
    account: es.account,
    feedbacks: es.feedbacks,
    languages: es.languages,
    brands: es.brands,
    brand: es.brand,
    clays: es.clays,
    clay: es.clay,
    projects: es.projects,
    project: es.project,
    user: es.user,
    'inventory-clays': es.inventoryClays,
    'inventory-clay': es.inventoryClay,
    'inventory-clay-lots': es.inventoryClayLots,
    'inventory-clay-lot': es.inventoryClayLot,
    'inventory-custom-clays': es.inventoryCustomClays,
    'inventory-custom-clay': es.inventoryCustomClay,
    'sign-in': es.signIn,
    'sign-up': es.signUp,
    'forgot-password': es.forgotPassword,
    'reset-password': es.resetPassword
  }
} as const

i18nUse(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: [
      'global',
      'languages',
      'feedbacks',
      'account',
      'brands',
      'brand',
      'clays',
      'clay',
      'projects',
      'project',
      'user',
      'signIn',
      'signUp',
      'forgotPassword',
      'resetPassword',
      'inventoryClays',
      'inventoryClay',
      'inventoryClayLots',
      'inventoryClayLot',
      'inventoryCustomClays',
      'inventoryCustomClay'
    ],
    defaultNS: 'global',
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: LANGUAGES,
    resources,
    interpolation: {
      escapeValue: false
    },
    detection: {
      lookupLocalStorage: LANGUAGE_KEY,
      order: ['localStorage']
    },
    debug: process.env.NODE_ENV !== 'production'
  })
