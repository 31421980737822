import { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import { PageContainer } from 'components'
import { useInventoryClayContext } from 'contexts'
import { useInventoryClayLotController } from 'controllers'
import { formatWeight } from 'utils'

import { PageMainButtonProps, PageMainButtonOutletContextProps } from '../types'
import { InventoryClayLotCard, DeleteInventoryClayLotDialog } from './components'
import { ClayImg, ClayTitle, LotsContainer } from './styles'

export const InventoryClayPage = ({ pageMainButton }: PageMainButtonProps) => {
  const { fetchInventoryClay, inventoryClay } = useInventoryClayContext()
  const { deleteInventoryClayLot } = useInventoryClayLotController(null, inventoryClay?.id)

  const [isDeleteLotDialogOpen, setIsDeleteLotDialogOpen] = useState<boolean>(false)
  const [selectedInventoryClayLotId, setSelectedInventoryClayLotId] = useState<string>()

  const selectedInventoryClayLot = inventoryClay?.inventoryClayLots.find(
    ({ id }) => id === selectedInventoryClayLotId
  )
  const { projectInventoriableClays } = selectedInventoryClayLot || {}

  const { setPageMainButton } = useOutletContext<PageMainButtonOutletContextProps>()
  const navigate = useNavigate()

  useEffect(() => {
    fetchInventoryClay()
  }, []) // eslint-disable-line

  useEffect(() => {
    setPageMainButton(pageMainButton)
  }, []) // eslint-disable-line

  useEffect(() => {
    return () => setIsDeleteLotDialogOpen(false)
  }, [])

  const handleSuccess = () => {
    if (inventoryClay.inventoryClayLots.length > 1) {
      return fetchInventoryClay()
    }
    navigate('..')
  }

  if (!inventoryClay) {
    return <>{t('loading')}</>
  }

  return (
    <PageContainer flexColumn fullWidth>
      <ClayImg src={inventoryClay.clay.photoUrl} />
      <ClayTitle>
        <Typography variant="h5" gutterBottom>
          {inventoryClay.clay.color}
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {inventoryClay.clay.brand.name}
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          {t('inventory-clay:weight')}: {formatWeight(inventoryClay.weight)}
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          {t('inventory-clay:available-weight')}: {formatWeight(inventoryClay.availableWeight)}
        </Typography>
      </ClayTitle>
      <LotsContainer>
        {inventoryClay.inventoryClayLots.map(inventoryClayLot => (
          <InventoryClayLotCard
            key={inventoryClayLot.id}
            inventoryClayLot={inventoryClayLot}
            onMenuClick={(inventoryClayLotId: Id) => {
              setSelectedInventoryClayLotId(inventoryClayLotId)
              setIsDeleteLotDialogOpen(true)
            }}
          />
        ))}
      </LotsContainer>

      <DeleteInventoryClayLotDialog
        open={isDeleteLotDialogOpen}
        onClose={() => setIsDeleteLotDialogOpen(false)}
        onSubmit={() => deleteInventoryClayLot({}, {}, { inventoryClayLotId: selectedInventoryClayLot.id })}
        onSuccess={handleSuccess}
        inventoryClayLotCount={projectInventoriableClays?.length}
      />
    </PageContainer>
  )
}
