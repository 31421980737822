import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import { useToastContext } from 'contexts'
import { useApi } from 'hooks'
import { InventoryCustomClay } from 'models'
import { InventoryCustomClayEndpoint } from 'routes/locations'

export const useInventoryCustomClayController = (inventoryCustomClayId: Id) => {
  const [fetchInventoryCustomClay, inventoryCustomClay] = useApi<InventoryCustomClay>(
    InventoryCustomClayEndpoint,
    'GET',
    { inventoryCustomClayId },
    InventoryCustomClay
  )

  const [deleteInventoryCustomClay, , , , deleteInventoryCustomClayStatus] = useApi<InventoryCustomClay>(
    InventoryCustomClayEndpoint,
    'DELETE',
    { inventoryCustomClayId }
  )

  const { showToast } = useToastContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (deleteInventoryCustomClayStatus !== 204) return

    showToast({
      type: 'success',
      title: t('inventory-custom-clay:success:delete:title'),
      description: t('inventory-custom-clay:success:delete:description')
    })
    navigate('./custom-clays')
  }, [deleteInventoryCustomClayStatus, showToast]) // eslint-disable-line

  return {
    inventoryCustomClay,
    fetchInventoryCustomClay,
    deleteInventoryCustomClay,
    deleteInventoryCustomClayStatus
  }
}
