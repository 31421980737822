type UseFinderProps<T> = {
  id: Id
} & T

export const useFinder = <T>(collection: UseFinderProps<T>[]) => {
  const findRecord = (id: Id) => {
    return collection?.find(record => record.id === id) as T
  }

  const findRecordBy = (attr: string, value: any) => {
    return collection?.find(record => record[attr] === value) as T
  }

  return { findRecord, findRecordBy }
}
