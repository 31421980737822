import { Clay, InventoryClayLot, User } from 'models'

export class InventoryClay {
  id: Id
  weight: number
  usedWeight: number
  clayId: Id

  clay: Clay
  user: User

  inventoryClayLots?: InventoryClayLot[]

  constructor(inventoryClayParams) {
    const { inventoryClayLots: inventoryClayLotsParams } = inventoryClayParams
    const inventoryClayLots = inventoryClayLotsParams?.map(params => new InventoryClayLot(params)) || []
    Object.assign(this, {
      ...inventoryClayParams,
      inventoryClayLots
    })
  }

  get availableWeight() {
    return this.weight - this.usedWeight
  }
}
