import styled, { css } from 'styled-components'
import { DefaultTheme } from '@mui/material/styles'

import { colors } from 'styles'

type StyledTextFieldProps = {
  $small?: boolean
} & DefaultTheme

export const Container = styled.div<StyledTextFieldProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 10px;
    height: 10px;
    background: ${colors.offWhite};
  }

  ${({ $small }) =>
    $small &&
    css`
      & > div {
        width: 5px;
        height: 5px;
      }
    `}
`
